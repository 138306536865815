import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from "moment";
import Footer from "./footer";
import profile from "../images/profileAvatar.jpg";
import tanktop from "../images/tank-top.png";
import star from "../images/star.png";
import silver from "../images/silver.png";
import bronze from "../images/bronze.png";

function Jugador() {

    const [data, setData] = useState({ data: [] });
    const [image, setImage] = useState({ data: "" });
    const [isFetched, setIsFetched] = useState(false);

    const params = useParams();
    const nombreJugador = params.nombreJugador;

    useEffect(() => {
        Promise.all([
            fetch('https://aguada-stats-jugadores-api.vercel.app/jugador/' + nombreJugador)
        ])
            .then(([response]) =>
                Promise.all([response.json()])
            )
            .then(([data, image]) => {
                setData(data);
                setImage('https://aguada-stats-imagenes-api.vercel.app/download/' + nombreJugador + '.jpg');
                setIsFetched(true);
            });
        document.title = nombreJugador;
    }, []);

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-10 pt-20">
            <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 inherit mb-5 pt-1 pb-3 px-4 rounded-lg">
                {isFetched ?
                    data.length === 0 ? 
                        <p className="text-center text-2xl my-3">No existe el jugador que buscas</p>
                    : <>
                        <div className="flex flex-col md:flex-row flex-auto">
                            <img src={data[0].pendImage ? profile : image} alt="Imagen de jugador" className="w-72 h-72 mx-auto mt-6 md:mt-4 md:ml-0 rounded-md shadow-md" />
                            <div className="m-auto mt-4 md:m-4 mr-0 w-full backdrop-opacity-40 bg-gradient-to-r from-white/30 to-white/70 rounded-md h-fit">
                                <div className=" bg-gradient-to-r from-green-600 to-green-500 text-white text-center p-2 mb-2 rounded-md shadow-md">
                                    <h1 className="text-2xl font-bold uppercase tracking-wider underline">{data[0].nombre}</h1>
                                </div>
                                {data.map(jugador => jugador.jugador ?
                                    <p className="font-semibold mt-1 w-fit px-2">Altura: <span className="font-normal">{jugador.altura}</span></p>
                                    : null)}
                                <p className="font-semibold mt-1 w-fit px-2">Nacimiento: <span className="font-normal">{data[0].nacimiento ? moment(data[0].nacimiento).format('DD/MM/YYYY') + ", " + data[0].nacionalidad : "-"}</span></p>
                                {data.map(jugador => jugador.jugador ?
                                    <p className="font-semibold mt-1 w-fit px-2">Debut: <span className="font-normal">{moment(jugador.debut).format('DD/MM/YYYY')}</span></p>
                                    : null)}
                                {data.map(jugador => jugador.jugador ?
                                    <p className="font-semibold mt-1 w-fit px-2">Torneos: <span className="font-normal">{jugador.torneos.map((torneo, i) => (i ? ', ' : '') + torneo.liga)}</span></p> 
                                    : null)}
                                {data.map(jugador => !jugador.jugador ?
                                    <p className="font-semibold mt-1 w-fit px-2">Debut DT: <span className="font-normal">{moment(jugador.debut).format('DD/MM/YYYY')}</span></p>
                                    : null)}
                                {data.map(jugador => !jugador.jugador ?
                                    <p className="font-semibold mt-1 w-fit px-2">Torneos DT: <span className="font-normal">{jugador.torneos.map((torneo, i) => (i ? ', ' : '') + torneo.liga)}</span></p> 
                                    : null)}
                                <div className="inline-block mb-1">
                                    {data.map(jugador => jugador.jugador ? jugador.numero.map(num => <>
                                        <div className="inline-block mt-4">
                                            <div className="relative text-center cursor-default text-black w-fit px-2 mr-3">
                                                <img src={tanktop} alt="Camiseta" className="w-12" />
                                                <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-xl font-semibold">{num}</div>
                                            </div>
                                        </div>
                                    </>) : null)}
                                </div>
                                <div className="inline-block float-right">
                                    {data.map(jugador => jugador.campeon ? jugador.campeon.map(num => <>
                                        <div className="inline-block">
                                            <div className="relative text-center cursor-default text-black px-2 w-20">
                                                <img src={star} alt="Oro" className="w-12 m-auto" />
                                                <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                <div className="block text-sm font-semibold">CAMPEÓN</div>
                                            </div>
                                        </div>
                                    </>) : null)}
                                    {data.map(jugador => jugador.segundo ? jugador.segundo.map(num => <>
                                        <div className="inline-block">
                                            <div className="relative text-center cursor-default text-black px-2 w-20">
                                                <img src={silver} alt="Plata" className="w-12 m-auto" />
                                                <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                <div className="block text-sm font-semibold">Segundo</div>
                                            </div>
                                        </div>
                                    </>) : null)}
                                    {data.map(jugador => jugador.tercero ? jugador.tercero.map(num => <>
                                        <div className="inline-block">
                                            <div className="relative text-center cursor-default text-black px-2 w-20">
                                                <img src={bronze} alt="Bronce" className="w-12 m-auto" />
                                                <div className="absolute top-1/2 left-1/2 -translate-y-[80%] -translate-x-1/2 text-sm font-semibold">{num}</div>
                                                <div className="block text-sm font-semibold">Tercero</div>
                                            </div>
                                        </div>
                                    </>) : null)}
                                </div>
                            </div>
                        </div>
                        {/* Promedios Jugador */}
                        {data.map((jugador, i) =>
                            jugador.jugador ?
                                <>
                                    <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l-2 border-t-2 border-r-2 border-black rounded-t-md">Promedios: </h1>
                                    <table className='text-sm w-full overflow-auto border border-black block rounded-md'>
                                        <thead>
                                            <tr className='text-bold bg-gray-300'>
                                                <th className="w-28 px-16 border-r border-b border-black">Liga</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Partidos</th>
                                                <th className="w-20 px-3 border-r border-b border-black">Ganados</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Perdidos</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Gan %</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                                <th className="w-20 px-3 border-r border-b border-black">Pts</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RT</th>
                                                <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                                <th className="w-20 px-3 border-r border-b border-black">TC H</th>
                                                <th className="w-20 px-3 border-r border-b border-black">TC L</th>
                                                <th className="w-20 px-3 border-r border-b border-black">TC%</th>
                                                <th className="w-28 px-0 border-r border-b border-black">2PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">2PTS L</th>
                                                <th className="w-28 px-4 border-r border-b border-black">2PTS%</th>
                                                <th className="w-28 px-0 border-r border-b border-black">3PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">3PTS L</th>
                                                <th className="w-28 px-4 border-r border-b border-black">3PTS%</th>
                                                <th className="w-28 px-0 border-r border-b border-black">1PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">1PTS L</th>
                                                <th className="w-28 px-4 border-r border-b border-black">1PTS%</th>
                                                <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                                <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                                <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                                <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                            </tr>
                                        </thead>

                                        {jugador.torneosProm.map((torneoProm, z) => <tbody>
                                            <tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneoProm.liga}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].partidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].ganados}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].perdidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{data[i].torneos[z].winPerc}</th>
                                                <th className="font-normal w-20 px-3 border-r border-b border-black">{(torneoProm.mpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ppg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ropg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.rdpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.rtpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.apg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tchpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tclpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tcperc}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tdhpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tdlpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tdperc}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tthpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.ttlpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.ttperc}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tlhpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.tllpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneoProm.tlperc}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.topg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.spg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.bpg).toFixed(2)}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{(torneoProm.fpg).toFixed(2)}</th>
                                            </tr>
                                        </tbody>
                                        )}
                                        <tfoot>
                                            {data.map((jugador, i) => jugador.jugador ?
                                                <tr className='text-bold bg-gray-300'>
                                                    <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.partidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ganados}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.perdidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.winPerc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.mpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ppg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ropg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.rdpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.rtpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.apg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tchpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tclpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tcperc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tdhpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tdlpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tdperc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tthpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.ttlpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.ttperc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tlhpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.tllpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carreraProm.tlperc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.topg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.spg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.bpg).toFixed(2)}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{(jugador.carreraProm.fpg).toFixed(2)}</th>
                                                </tr>
                                                : null
                                            )}
                                        </tfoot>
                                    </table>
                                </>
                                : null)}
                        {/* Totales Jugador */}
                        {data.map((jugador, i) =>
                            jugador.jugador ?
                                <>
                                    <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l-2 border-t-2 border-r-2 border-black rounded-t-md">Totales: </h1>
                                    <table className='text-sm w-full overflow-auto border border-black block rounded-md'>
                                        <thead>
                                            <tr className='text-bold bg-gray-300'>
                                                <th className="w-28 px-16 border-r border-b border-black">Liga</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Partidos</th>
                                                <th className="w-20 px-3 border-r border-b border-black">Ganados</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Perdidos</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Gan %</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                                <th className="w-20 px-3 border-r border-b border-black">Pts</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                                <th className="w-28 px-3 border-r border-b border-black">RT</th>
                                                <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                                <th className="w-20 px-0 border-r border-b border-black">TC H</th>
                                                <th className="w-20 px-0 border-r border-b border-black">TC L</th>
                                                <th className="w-32 px-0 border-r border-b border-black">2PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">2PTS L</th>
                                                <th className="w-28 px-0 border-r border-b border-black">3PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">3PTS L</th>
                                                <th className="w-28 px-0 border-r border-b border-black">1PTS H</th>
                                                <th className="w-28 px-0 border-r border-b border-black">1PTS L</th>
                                                <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                                <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                                <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                                <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                            </tr>
                                        </thead>
                                        {jugador.torneos.map((torneo, z) => <tbody>
                                            <tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.liga}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.partidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.ganados}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.perdidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.winPerc}</th>
                                                <th className="font-normal w-20 px-3 border-r border-b border-black">{torneo.min}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.pts}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.ro}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.rd}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.rt}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.ast}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tch}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tcl}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tdh}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tdl}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tth}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.ttl}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tlh}</th>
                                                <th className="font-normal w-24 px-6 border-r border-b border-black">{torneo.tll}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.to}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.stl}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.bls}</th>
                                                <th className="font-normal w-24 px-4 border-r border-b border-black">{torneo.fls}</th>
                                            </tr>
                                        </tbody>
                                        )}
                                        <tfoot>
                                            {data.map((jugador, i) => jugador.jugador ?
                                                <tr className='text-bold bg-gray-300'>
                                                    <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.partidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ganados}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.perdidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.winPerc}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.min}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.pts}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ro}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.rd}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.rt}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ast}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tch}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tcl}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tdh}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tdl}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tth}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ttl}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tlh}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.tll}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.to}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.stl}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.bls}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.fls}</th>
                                                </tr>
                                                : null
                                            )}
                                        </tfoot>
                                    </table>
                                </>
                                : null)}
                        {/* Técnico */}
                        {data.map((jugador, i) =>
                            !jugador.jugador ?
                                <>
                                    <h1 className="font-semibold mt-4 ml-2 bg-gray-300 w-fit p-2 shadow-md outline outline-1 outline-gray-300 border-l-2 border-t-2 border-r-2 border-black rounded-t-md">DT: </h1>
                                    <table className='text-sm w-full md:w-fit overflow-auto border border-black block mb-2 rounded-md'>
                                        <thead>
                                            <tr className='text-bold bg-gray-300'>
                                                <th className="w-28 px-16 border-r border-b border-black">Liga</th>
                                                <th className="w-28 px-4 border-r border-b border-black">Partidos</th>
                                                <th className="w-20 px-3 border-r border-b border-black">Ganados</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Perdidos</th>
                                                <th className="w-24 px-4 border-r border-b border-black">Gan %</th>
                                            </tr>
                                        </thead>

                                        {jugador.torneos.map(torneo => <tbody>
                                            <tr className='text-center bg-gray-100 hover:bg-gray-200 border'>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.liga}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.partidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.ganados}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.perdidos}</th>
                                                <th className="font-normal w-28 px-4 border-r border-b border-black">{torneo.winPerc}</th>
                                            </tr>
                                        </tbody>
                                        )}
                                        <tfoot>
                                            {data.map((jugador, i) => !jugador.jugador ?
                                                <tr className='text-bold bg-gray-300'>
                                                    <th className="w-28 px-16 border-r border-b border-black">Carrera</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.partidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.ganados}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.perdidos}</th>
                                                    <th className="w-28 px-4 border-r border-b border-black">{jugador.carrera.winPerc}</th>
                                                </tr>
                                                : null
                                            )}
                                        </tfoot>
                                    </table>
                                </>
                                : null)}
                    </>
                    :
                    <div className="backdrop-blur-xl bg-white/20 p-10 rounded-md">
                        <ReactLoading type="bars" color="#15803d" width={'10%'} className="text-center m-auto mb-16" />
                        <p className="text-center text-lg font-semibold">Buscando jugador...</p>
                    </div>
                }

            </div>
            <Footer></Footer>

        </div>
    );
}

export default Jugador;
