import React from "react";

function Footer() {

    return (
        <>
            <div className="text-center text-sm text-white">
                {/* <footer>Hecho por <a href="https://www.emiliano-correa.com" className="underline hover:text-slate-300">Emiliano Correa Roger</a></footer> */}
                <footer className="underline hover:text-slate-300">Hecho por Emiliano Correa Roger</footer>
            </div>
        </>
    );
}

export default Footer;
