import React, { useState } from "react";
import { Link } from "react-router-dom";
import escudo from '../images/escudo.png'

function NotFound() {

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover h-screen text-center overflow-hidden pt-20">
            <h1 className="text-white font-bold text-5xl mb-3">¡Página no encontrada!</h1>
            <img src={escudo} width="100" className="m-auto w-1/3 mb-5" alt="Escudo de Aguada" />
            <p className="text-2xl text-white">Parece que la página que buscas no existe</p>
            <Link to='/' className="text-xl text-white underline mt-2">Volver a página principal</Link>
        </div>
    );
}

export default NotFound;
