import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from "moment";
import Footer from "./footer";

function Partido() {

    const params = useParams();
    const partidoId = params.partidoId;

    const [data, setData] = useState({ data: [] });
    const [isFetched, setIsFetched] = useState(false);

    async function fetchPartido() {
        const data = await fetch('https://aguada-stats-api.vercel.app/partidos/' + partidoId);
        data.json().then(data => {
            setData(data);
            setIsFetched(true);
        });
    }

    useEffect(() => {
        fetchPartido();
    }, []);

    const setTitle = () => {
        document.title = data.liga + " | " + data.resultado;
    }

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-10 pt-20">
            {isFetched ?
                <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 inherit mb-5 py-1 rounded-lg">
                    {setTitle()}
                    <h1 className="hidden">{data._id}</h1>
                    <div className="bg-gray-100 flex py-2 rounded-md mx-1 sm:mx-3 mt-3 shadow-md">
                        <div className={`w-1/2 py-4 pl-4 border-r-8 ${data.local && data.ganado ? "border-green-600" :
                            !data.local && data.ganado ? "border-red-600" :
                                data.local && !data.ganado ? "border-red-600" :
                                    !data.local && !data.ganado ? "border-green-600" : ""}`}>
                            <div className={`font-semibold text-xl border-b-4 ${data.local && data.ganado ? "border-green-600" :
                                !data.local && data.ganado ? "border-red-600" :
                                    data.local && !data.ganado ? "border-red-600" :
                                        !data.local && !data.ganado ? "border-green-600" : ""}`}>
                                <span className="font-semibold text-xl uppercase">{data.local ? "Aguada" : data.adversario}</span>
                                <span className="font-semibold text-xl float-right mr-2 sm:mr-4">{data.local ? data.totalAguadaStats.totalPuntos : data.totalAdversarioStats.totalPuntos}</span>
                            </div>
                        </div>
                        <div className={`w-1/2 py-4 pr-4 border-l-8 ${data.local && data.ganado ? "border-red-600" :
                            !data.local && data.ganado ? "border-green-600" :
                                data.local && !data.ganado ? "border-green-600" :
                                    !data.local && !data.ganado ? "border-red-600" : ""}`}>
                            <div className={`font-semibold text-xl border-b-4 ${data.local && data.ganado ? "border-red-600" :
                                !data.local && data.ganado ? "border-green-600" :
                                    data.local && !data.ganado ? "border-green-600" :
                                        !data.local && !data.ganado ? "border-red-600" : ""}`}>
                                <span className="font-semibold text-xl ml-2 sm:ml-4">{data.local ? data.totalAdversarioStats.totalPuntos : data.totalAguadaStats.totalPuntos}</span>
                                <span className="font-semibold text-xl float-right uppercase">{data.local ? data.adversario : "Aguada"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100 text-center m-auto shadow-md w-4/5 rounded-b-md p-2">
                        <h1>{data.liga}</h1>
                        <h1>{data.temporadaRegular ?  'Temp Reg Fecha ' + data.fechaLiga : 
                            data.superLiga ? 'Superliga Fecha ' + data.fechaLiga : 
                                data.reclasificatorio ? 'Reclasificatorio Fecha ' + data.fechaLiga :
                                    data.liguilla ? 'Liguilla Fecha ' + data.fechaLiga : data.playoffsEtapa + ' Partido ' + data.fechaLiga }</h1>
                        
                        <h1>{moment(data.fecha).format('DD/MM/YYYY')}</h1>
                        <h1>Cancha: {data.cancha}</h1>
                        <h1>Jueces: {data.jueces[0] !== "-" ? data.jueces[0] + ", " + data.jueces[1] + ", " + data.jueces[2] : "-"}</h1>
                    </div>

                    {/* Local */}
                    {data.aguadaStats.length > 0 ?
                        <>
                            <div className="px-2 pt-2 mt-4">
                                <span className={`text-2xl font-bold bg-gray-200 px-2 pt-2 rounded-t-md underline ${data.local && data.ganado ? "decoration-green-600 text-green-600" :
                                    !data.local && data.ganado ? "decoration-red-600 text-red-600" :
                                        data.local && !data.ganado ? "decoration-red-600 text-red-600" :
                                            !data.local && !data.ganado ? "decoration-green-600 text-green-600" : ""}`}>
                                    {data.local ? "Aguada" : data.adversario}
                                </span>
                                <span className="float-right bg-gray-200 px-2 pt-2 rounded-t-md">{data.local ? <a href={`../jugador/${data.dtAguada}`} className="underline hover:text-slate-500">{data.dtAguada}</a> : data.dtAdversario}</span>
                            </div>
                            <table className='text-sm w-full overflow-auto block rounded-md'>
                                <thead>
                                    <tr className='text-bold bg-gray-200'>
                                        {data.aguadaStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-b border-black">N°</th> : null}
                                        <th className="w-28 px-16 border-r border-b border-black">Jugador</th>
                                        <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                        <th className="w-20 px-3 border-r-2 border-b border-black">Pts</th>
                                        {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-b border-black">LC</th> : null}
                                        {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-b border-black">LC%</th> : null}
                                        <th className="w-24 px-4 border-r border-b border-black">2PTS</th>
                                        <th className="w-24 px-4 border-r-2 border-b border-black">2PTS%</th>
                                        <th className="w-24 px-4 border-r border-b border-black">3PTS</th>
                                        <th className="w-28 px-4 border-r-2 border-b border-black">3PTS%</th>
                                        <th className="w-28 px-4 border-r border-b border-black">1PTS</th>
                                        <th className="w-28 px-4 border-r-2 border-b border-black">1PTS%</th>
                                        <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                        <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                        <th className="w-28 px-3 border-r-2 border-b border-black">RT</th>
                                        <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                        <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                        <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                        <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                        {data.aguadaStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">BR</th> : null}
                                        <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                        <th className="w-28 px-3 border-r border-b border-black">FR</th>
                                        {data.aguadaStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-b border-black">+/-</th> : null}
                                        <th className="w-28 px-3 border-r border-b border-black">EF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.local ?
                                        data.aguadaStats.map(jugador => <tr className='text-center bg-white hover:bg-white/70 border'>
                                            {jugador.numero >= 0 ? <td className="border-r border-r-black">{jugador.numero}</td> : null}
                                            <td className="border-r border-r-black"><a href={`../jugador/${jugador.jugador}`} className="underline hover:text-slate-500">{jugador.jugador}</a></td>
                                            <td className="border-r border-r-black">{jugador.minutos}</td>
                                            <td className="border-l border-r-2 border-black">{jugador.puntos}</td>
                                            {jugador.camposHechos !== undefined ? <td className="border-r border-r-black">{jugador.camposHechos}/{jugador.camposIntent}</td> : null}
                                            {jugador.camposPercent !== undefined ? <td className="border-r-2 border-r-black">{jugador.camposPercent}%</td> : null}
                                            <td className="border-r border-r-black">{jugador.doblesHechos}/{jugador.doblesIntent}</td>
                                            {jugador.doblesPercent ? <td className="border-r-2 border-r-black">{jugador.doblesPercent}%</td> :
                                                jugador.doblesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.doblesHechos / jugador.doblesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.triplesHechos}/{jugador.triplesIntent}</td>
                                            {jugador.triplesPercent ? <td className="border-r-2 border-r-black">{jugador.triplesPercent}%</td> :
                                                jugador.triplesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.triplesHechos / jugador.triplesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.libresHechos}/{jugador.libresIntent}</td>
                                            {jugador.libresPercent ? <td className="border-r-2 border-r-black">{jugador.libresPercent}%</td> :
                                                jugador.libresHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.libresHechos / jugador.libresIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.rebotesOff}</td>
                                            <td className="border-r border-r-black">{jugador.rebotesDef}</td>
                                            <td className="border-r-2 border-r-black">{jugador.rebotesTot}</td>
                                            <td className="border-r border-r-black">{jugador.asistencias}</td>
                                            <td className="border-r border-r-black">{jugador.perdidas}</td>
                                            <td className="border-r border-r-black">{jugador.robos}</td>
                                            <td className="border-r border-r-black">{jugador.tapas}</td>
                                            {jugador.tapasRec !== undefined ? <td className="border-r border-r-black">{jugador.tapasRec}</td> : null}
                                            <td className="border-r border-r-black">{jugador.fouls}</td>
                                            <td className="border-r border-r-black">{jugador.foulsRec}</td>
                                            {jugador.masMenos !== undefined ? <td className="border-r border-r-black">{jugador.masMenos}</td> : null}
                                            <td className="border-r border-r-black">{jugador.valor}</td>
                                        </tr>
                                        ) :
                                        data.adversarioStats.map(jugador => <tr className='text-center bg-white hover:bg-white/70 border'>
                                            {jugador.numero >= 0 ? <td className="border-r border-r-black">{jugador.numero}</td> : null}
                                            <td className="border-r border-r-black">{jugador.jugador}</td>
                                            <td className="border-r border-r-black">{jugador.minutos}</td>
                                            <td className="border-l border-r-2 border-black">{jugador.puntos}</td>
                                            {jugador.camposHechos !== undefined ? <td className="border-r border-r-black">{jugador.camposHechos}/{jugador.camposIntent}</td> : null}
                                            {jugador.camposPercent !== undefined ? <td className="border-r-2 border-r-black">{jugador.camposPercent}%</td> : null}
                                            <td className="border-r border-r-black">{jugador.doblesHechos}/{jugador.doblesIntent}</td>
                                            {jugador.doblesPercent ? <td className="border-r-2 border-r-black">{jugador.doblesPercent}%</td> :
                                                jugador.doblesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.doblesHechos / jugador.doblesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.triplesHechos}/{jugador.triplesIntent}</td>
                                            {jugador.triplesPercent ? <td className="border-r-2 border-r-black">{jugador.triplesPercent}%</td> :
                                                jugador.triplesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.triplesHechos / jugador.triplesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.libresHechos}/{jugador.libresIntent}</td>
                                            {jugador.libresPercent ? <td className="border-r-2 border-r-black">{jugador.libresPercent}%</td> :
                                                jugador.libresHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.libresHechos / jugador.libresIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.rebotesOff}</td>
                                            <td className="border-r border-r-black">{jugador.rebotesDef}</td>
                                            <td className="border-r-2 border-r-black">{jugador.rebotesTot}</td>
                                            <td className="border-r border-r-black">{jugador.asistencias}</td>
                                            <td className="border-r border-r-black">{jugador.perdidas}</td>
                                            <td className="border-r border-r-black">{jugador.robos}</td>
                                            <td className="border-r border-r-black">{jugador.tapas}</td>
                                            {jugador.tapasRec !== undefined ? <td className="border-r border-r-black">{jugador.tapasRec}</td> : null}
                                            <td className="border-r border-r-black">{jugador.fouls}</td>
                                            <td className="border-r border-r-black">{jugador.foulsRec}</td>
                                            {jugador.masMenos !== undefined ? <td className="border-r border-r-black">{jugador.masMenos}</td> : null}
                                            <td className="border-r border-r-black">{jugador.valor}</td>
                                        </tr>
                                        )}
                                </tbody>
                                <tfoot>
                                    <tr className='text-bold bg-gray-200'>
                                        {data.local ?
                                            <>
                                                {data.aguadaStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-16 border-r border-t border-black"></th>
                                                <th className="w-28 px-4 border-r border-t border-black"></th>
                                                <th className="w-20 px-3 border-r-2 border-t border-black">{data.totalAguadaStats.totalPuntos}</th>
                                                {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-t border-black">{data.totalAguadaStats.totalCamposHechos}/{data.totalAguadaStats.totalCamposIntent}</th> : null}
                                                {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalCamposPercent}%</th> : null}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAguadaStats.totalDoblesHechos}/{data.totalAguadaStats.totalDoblesIntentados}</th>
                                                {data.totalAguadaStats.totalDoblesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalDoblesPercent}%</td> :
                                                    data.totalAguadaStats.totalDoblesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalDoblesHechos / data.totalAguadaStats.totalDoblesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAguadaStats.totalTriplesHechos}/{data.totalAguadaStats.totalTriplesIntentados}</th>
                                                {data.totalAguadaStats.totalTriplesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalTriplesPercent}%</td> :
                                                    data.totalAguadaStats.totalTriplesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalTriplesHechos / data.totalAguadaStats.totalTriplesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-4 border-r border-t border-black">{data.totalAguadaStats.totalLibresHechos}/{data.totalAguadaStats.totalLibresIntentados}</th>
                                                {data.totalAguadaStats.totalLibresPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalLibresPercent}%</td> :
                                                    data.totalAguadaStats.totalLibresHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalLibresHechos / data.totalAguadaStats.totalLibresIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRebotesOff}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRebotesDef}</th>
                                                <th className="w-28 px-3 border-r-2 border-t border-black">{data.totalAguadaStats.totalRebotesTot}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalAsistencias}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalPerdidas}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRobos}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalTapas}</th>
                                                {data.aguadaStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-t border-black">{data.totalAguadaStats.totalTapasRec}</th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalFouls}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalFoulsRec}</th>
                                                {data.aguadaStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black"></th>
                                            </> :
                                            <>
                                                {data.adversarioStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-16 border-r border-t border-black"></th>
                                                <th className="w-28 px-4 border-r border-t border-black"></th>
                                                <th className="w-20 px-3 border-r-2 border-t border-black">{data.totalAdversarioStats.totalPuntos}</th>
                                                {data.adversarioStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalCamposHechos}/{data.totalAdversarioStats.totalCamposIntent}</th> : null}
                                                {data.adversarioStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalCamposPercent}%</th> : null}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalDoblesHechos}/{data.totalAdversarioStats.totalDoblesIntentados}</th>
                                                {data.totalAdversarioStats.totalDoblesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalDoblesPercent}%</td> :
                                                    data.totalAdversarioStats.totalDoblesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalDoblesHechos / data.totalAdversarioStats.totalDoblesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalTriplesHechos}/{data.totalAdversarioStats.totalTriplesIntentados}</th>
                                                {data.totalAdversarioStats.totalTriplesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalTriplesPercent}%</td> :
                                                    data.totalAdversarioStats.totalTriplesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalTriplesHechos / data.totalAdversarioStats.totalTriplesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalLibresHechos}/{data.totalAdversarioStats.totalLibresIntentados}</th>
                                                {data.totalAdversarioStats.totalLibresPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalLibresPercent}%</td> :
                                                    data.totalAdversarioStats.totalLibresHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalLibresHechos / data.totalAdversarioStats.totalLibresIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRebotesOff}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRebotesDef}</th>
                                                <th className="w-28 px-3 border-r-2 border-t border-black">{data.totalAdversarioStats.totalRebotesTot}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalAsistencias}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalPerdidas}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRobos}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalTapas}</th>
                                                {data.adversarioStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalTapasRec}</th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalFouls}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalFoulsRec}</th>
                                                {data.adversarioStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black"></th>
                                            </>}
                                    </tr>
                                </tfoot>
                            </table>
                        </>
                        : null}
                    {data.aguadaStats.length > 0 && data.local ?
                        data.totalAguadaStats.puntosDePerdida ?
                            <div className="bg-gray-100 mt-4 p-4 md:text-center rounded-md shadow-md">
                                <div className={`p-2 mr-4 md:mr-0 rounded-md text-white float-left ${data.local && data.ganado ? "bg-green-600" :
                                    !data.local && data.ganado ? "bg-red-600" :
                                        data.local && !data.ganado ? "bg-red-600" :
                                            !data.local && !data.ganado ? "bg-green-600" : ""}`}>Estadísticas de equipo: </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos de pérdida: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos en pintura: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosEnPintura}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos segunda oportunidad: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosSegundaOport}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos contraataque: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosContraataque}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos del banco: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor ventaja: </span>
                                    <span className="px-1">{data.totalAguadaStats.mayorVentaja}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor racha: </span>
                                    <span className="px-1">{data.totalAguadaStats.mayorRacha}</span>
                                </div>
                            </div>
                            : null :
                        data.totalAdversarioStats.puntosDePerdida ?
                            <div className="bg-gray-100 mt-4 p-4 md:text-center rounded-md shadow-md">
                                <div className={`p-2 mr-4 md:mr-0 rounded-md text-white float-left ${data.local && data.ganado ? "bg-green-600" :
                                    !data.local && data.ganado ? "bg-red-600" :
                                        data.local && !data.ganado ? "bg-red-600" :
                                            !data.local && !data.ganado ? "bg-green-600" : ""}`}>Estadísticas de equipo: </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos de pérdida: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos en pintura: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosEnPintura}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos segunda oportunidad: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosSegundaOport}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos contraataque: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosContraataque}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos del banco: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor ventaja: </span>
                                    <span className="px-1">{data.totalAdversarioStats.mayorVentaja}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor racha: </span>
                                    <span className="px-1">{data.totalAdversarioStats.mayorRacha}</span>
                                </div>
                            </div> : null
                    }

                    {/* Visitante */}

                    {data.aguadaStats.length > 0 ?
                        <>
                            <div className="px-2 pt-8 mt-4">
                                <span className={`text-2xl font-bold bg-gray-200 px-2 pt-2 rounded-t-md underline ${data.local && data.ganado ? "decoration-red-600 text-red-600" :
                                    !data.local && data.ganado ? "decoration-green-600 text-green-600" :
                                        data.local && !data.ganado ? "decoration-green-600 text-green-600" :
                                            !data.local && !data.ganado ? "decoration-red-600 text-red-600" : ""}`}>
                                    {!data.local ? "Aguada" : data.adversario}
                                </span>
                                <span className="float-right bg-gray-200 px-2 pt-2 rounded-t-md">{!data.local ? <a href={`../jugador/${data.dtAguada}`} className="underline hover:text-slate-500">{data.dtAguada}</a> : data.dtAdversario}</span>
                            </div>
                            <table className='text-sm w-full overflow-auto block rounded-md'>
                                <thead>
                                    <tr className='text-bold bg-gray-200'>
                                        {data.aguadaStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-b border-black">N°</th> : null}
                                        <th className="w-28 px-16 border-r border-b border-black">Jugador</th>
                                        <th className="w-28 px-4 border-r border-b border-black">Min</th>
                                        <th className="w-20 px-3 border-r-2 border-b border-black">Pts</th>
                                        {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-b border-black">LC</th> : null}
                                        {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-b border-black">LC%</th> : null}
                                        <th className="w-24 px-4 border-r border-b border-black">2PTS</th>
                                        <th className="w-24 px-4 border-r-2 border-b border-black">2PTS%</th>
                                        <th className="w-24 px-4 border-r border-b border-black">3PTS</th>
                                        <th className="w-28 px-4 border-r-2 border-b border-black">3PTS%</th>
                                        <th className="w-28 px-4 border-r border-b border-black">1PTS</th>
                                        <th className="w-28 px-4 border-r-2 border-b border-black">1PTS%</th>
                                        <th className="w-28 px-3 border-r border-b border-black">RO</th>
                                        <th className="w-28 px-3 border-r border-b border-black">RD</th>
                                        <th className="w-28 px-3 border-r-2 border-b border-black">RT</th>
                                        <th className="w-28 px-3 border-r border-b border-black">AS</th>
                                        <th className="w-28 px-3 border-r border-b border-black">PER</th>
                                        <th className="w-28 px-3 border-r border-b border-black">ROB</th>
                                        <th className="w-28 px-3 border-r border-b border-black">BLQ</th>
                                        {data.aguadaStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-b border-black">BR</th> : null}
                                        <th className="w-28 px-3 border-r border-b border-black">FP</th>
                                        <th className="w-28 px-3 border-r border-b border-black">FR</th>
                                        {data.aguadaStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-b border-black">+/-</th> : null}
                                        <th className="w-28 px-3 border-r border-b border-black">EF</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!data.local ?
                                        data.aguadaStats.map(jugador => <tr className='text-center bg-white hover:bg-white/70 border'>
                                            {jugador.numero >= 0 ? <td className="border-r border-r-black">{jugador.numero}</td> : null}
                                            <td className="border-r border-r-black"><a href={`../jugador/${jugador.jugador}`} className="underline hover:text-slate-500">{jugador.jugador}</a></td>
                                            <td className="border-r border-r-black">{jugador.minutos}</td>
                                            <td className="border-l border-r-2 border-black">{jugador.puntos}</td>
                                            {jugador.camposHechos !== undefined ? <td className="border-r border-r-black">{jugador.camposHechos}/{jugador.camposIntent}</td> : null}
                                            {jugador.camposPercent !== undefined ? <td className="border-r-2 border-r-black">{jugador.camposPercent}%</td> : null}
                                            <td className="border-r border-r-black">{jugador.doblesHechos}/{jugador.doblesIntent}</td>
                                            {jugador.doblesPercent ? <td className="border-r-2 border-r-black">{jugador.doblesPercent}%</td> :
                                                jugador.doblesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.doblesHechos / jugador.doblesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.triplesHechos}/{jugador.triplesIntent}</td>
                                            {jugador.triplesPercent ? <td className="border-r-2 border-r-black">{jugador.triplesPercent}%</td> :
                                                jugador.triplesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.triplesHechos / jugador.triplesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.libresHechos}/{jugador.libresIntent}</td>
                                            {jugador.libresPercent ? <td className="border-r-2 border-r-black">{jugador.libresPercent}%</td> :
                                                jugador.libresHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.libresHechos / jugador.libresIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.rebotesOff}</td>
                                            <td className="border-r border-r-black">{jugador.rebotesDef}</td>
                                            <td className="border-r-2 border-r-black">{jugador.rebotesTot}</td>
                                            <td className="border-r border-r-black">{jugador.asistencias}</td>
                                            <td className="border-r border-r-black">{jugador.perdidas}</td>
                                            <td className="border-r border-r-black">{jugador.robos}</td>
                                            <td className="border-r border-r-black">{jugador.tapas}</td>
                                            {jugador.tapasRec !== undefined ? <td className="border-r border-r-black">{jugador.tapasRec}</td> : null}
                                            <td className="border-r border-r-black">{jugador.fouls}</td>
                                            <td className="border-r border-r-black">{jugador.foulsRec}</td>
                                            {jugador.masMenos !== undefined ? <td className="border-r border-r-black">{jugador.masMenos}</td> : null}
                                            <td className="border-r border-r-black">{jugador.valor}</td>
                                        </tr>
                                        ) :
                                        data.adversarioStats.map(jugador => <tr className='text-center bg-white hover:bg-white/70 border'>
                                            {jugador.numero >= 0 ? <td className="border-r border-r-black">{jugador.numero}</td> : null}
                                            <td className="border-r border-r-black">{jugador.jugador}</td>
                                            <td className="border-r border-r-black">{jugador.minutos}</td>
                                            <td className="border-l border-r-2 border-black">{jugador.puntos}</td>
                                            {jugador.camposHechos !== undefined ? <td className="border-r border-r-black">{jugador.camposHechos}/{jugador.camposIntent}</td> : null}
                                            {jugador.camposPercent !== undefined ? <td className="border-r-2 border-r-black">{jugador.camposPercent}%</td> : null}
                                            <td className="border-r border-r-black">{jugador.doblesHechos}/{jugador.doblesIntent}</td>
                                            {jugador.doblesPercent ? <td className="border-r-2 border-r-black">{jugador.doblesPercent}%</td> :
                                                jugador.doblesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.doblesHechos / jugador.doblesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.triplesHechos}/{jugador.triplesIntent}</td>
                                            {jugador.triplesPercent ? <td className="border-r-2 border-r-black">{jugador.triplesPercent}%</td> :
                                                jugador.triplesHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.triplesHechos / jugador.triplesIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.libresHechos}/{jugador.libresIntent}</td>
                                            {jugador.libresPercent ? <td className="border-r-2 border-r-black">{jugador.libresPercent}%</td> :
                                                jugador.libresHechos > 0 ? <td className="border-r-2 border-r-black">{Math.round((jugador.libresHechos / jugador.libresIntent).toFixed(2) * 100)}%</td> :
                                                    <td className="border-r-2 border-r-black">0%</td>}
                                            <td className="border-r border-r-black">{jugador.rebotesOff}</td>
                                            <td className="border-r border-r-black">{jugador.rebotesDef}</td>
                                            <td className="border-r-2 border-r-black">{jugador.rebotesTot}</td>
                                            <td className="border-r border-r-black">{jugador.asistencias}</td>
                                            <td className="border-r border-r-black">{jugador.perdidas}</td>
                                            <td className="border-r border-r-black">{jugador.robos}</td>
                                            <td className="border-r border-r-black">{jugador.tapas}</td>
                                            {jugador.tapasRec !== undefined ? <td className="border-r border-r-black">{jugador.tapasRec}</td> : null}
                                            <td className="border-r border-r-black">{jugador.fouls}</td>
                                            <td className="border-r border-r-black">{jugador.foulsRec}</td>
                                            {jugador.masMenos !== undefined ? <td className="border-r border-r-black">{jugador.masMenos}</td> : null}
                                            <td className="border-r border-r-black">{jugador.valor}</td>
                                        </tr>
                                        )}
                                </tbody>
                                <tfoot>
                                    <tr className='text-bold bg-gray-200'>
                                        {!data.local ?
                                            <>
                                                {data.aguadaStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-16 border-r border-t border-black"></th>
                                                <th className="w-28 px-4 border-r border-t border-black"></th>
                                                <th className="w-20 px-3 border-r-2 border-t border-black">{data.totalAguadaStats.totalPuntos}</th>
                                                {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-t border-black">{data.totalAguadaStats.totalCamposHechos}/{data.totalAguadaStats.totalCamposIntent}</th> : null}
                                                {data.aguadaStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalCamposPercent}%</th> : null}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAguadaStats.totalDoblesHechos}/{data.totalAguadaStats.totalDoblesIntentados}</th>
                                                {data.totalAguadaStats.totalDoblesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalDoblesPercent}%</td> :
                                                    data.totalAguadaStats.totalDoblesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalDoblesHechos / data.totalAguadaStats.totalDoblesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAguadaStats.totalTriplesHechos}/{data.totalAguadaStats.totalTriplesIntentados}</th>
                                                {data.totalAguadaStats.totalTriplesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalTriplesPercent}%</td> :
                                                    data.totalAguadaStats.totalTriplesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalTriplesHechos / data.totalAguadaStats.totalTriplesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-4 border-r border-t border-black">{data.totalAguadaStats.totalLibresHechos}/{data.totalAguadaStats.totalLibresIntentados}</th>
                                                {data.totalAguadaStats.totalLibresPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAguadaStats.totalLibresPercent}%</td> :
                                                    data.totalAguadaStats.totalLibresHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAguadaStats.totalLibresHechos / data.totalAguadaStats.totalLibresIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRebotesOff}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRebotesDef}</th>
                                                <th className="w-28 px-3 border-r-2 border-t border-black">{data.totalAguadaStats.totalRebotesTot}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalAsistencias}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalPerdidas}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalRobos}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalTapas}</th>
                                                {data.aguadaStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-t border-black">{data.totalAguadaStats.totalTapasRec}</th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalFouls}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAguadaStats.totalFoulsRec}</th>
                                                {data.aguadaStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black"></th>
                                            </> :
                                            <>
                                                {data.adversarioStats[0].numero !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-16 border-r border-t border-black"></th>
                                                <th className="w-28 px-4 border-r border-t border-black"></th>
                                                <th className="w-20 px-3 border-r-2 border-t border-black">{data.totalAdversarioStats.totalPuntos}</th>
                                                {data.adversarioStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalCamposHechos}/{data.totalAdversarioStats.totalCamposIntent}</th> : null}
                                                {data.adversarioStats[0].camposHechos !== undefined ? <th className="w-12 px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalCamposPercent}%</th> : null}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalDoblesHechos}/{data.totalAdversarioStats.totalDoblesIntentados}</th>
                                                {data.totalAdversarioStats.totalDoblesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalDoblesPercent}%</td> :
                                                    data.totalAdversarioStats.totalDoblesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalDoblesHechos / data.totalAdversarioStats.totalDoblesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-24 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalTriplesHechos}/{data.totalAdversarioStats.totalTriplesIntentados}</th>
                                                {data.totalAdversarioStats.totalTriplesPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalTriplesPercent}%</td> :
                                                    data.totalAdversarioStats.totalTriplesHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalTriplesHechos / data.totalAdversarioStats.totalTriplesIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-4 border-r border-t border-black">{data.totalAdversarioStats.totalLibresHechos}/{data.totalAdversarioStats.totalLibresIntentados}</th>
                                                {data.totalAdversarioStats.totalLibresPercent ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{data.totalAdversarioStats.totalLibresPercent}%</td> :
                                                    data.totalAdversarioStats.totalLibresHechos > 0 ? <td className="w-28 text-center px-4 border-r-2 border-t border-black">{Math.round((data.totalAdversarioStats.totalLibresHechos / data.totalAdversarioStats.totalLibresIntentados).toFixed(2) * 100)}%</td> :
                                                        <td className="w-28 text-center px-4 border-r-2 border-t border-black">0%</td>}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRebotesOff}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRebotesDef}</th>
                                                <th className="w-28 px-3 border-r-2 border-t border-black">{data.totalAdversarioStats.totalRebotesTot}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalAsistencias}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalPerdidas}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalRobos}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalTapas}</th>
                                                {data.adversarioStats[0].tapasRec !== undefined ? <th className="w-12 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalTapasRec}</th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalFouls}</th>
                                                <th className="w-28 px-3 border-r border-t border-black">{data.totalAdversarioStats.totalFoulsRec}</th>
                                                {data.adversarioStats[0].masMenos !== undefined ? <th className="w-12 px-3 border-r border-t border-black"></th> : null}
                                                <th className="w-28 px-3 border-r border-t border-black"></th>
                                            </>}
                                    </tr>
                                </tfoot>
                            </table>
                        </>
                        : null}
                    {data.aguadaStats.length > 0 && !data.local ?
                        data.totalAguadaStats.puntosDePerdida ?
                            <div className="bg-gray-100 mt-4 p-4 md:text-center rounded-md shadow-md">
                                <div className={`p-2 mr-4 md:mr-0 rounded-md text-white float-left ${data.local && data.ganado ? "bg-red-600" :
                                    !data.local && data.ganado ? "bg-green-600" :
                                        data.local && !data.ganado ? "bg-green-600" :
                                            !data.local && !data.ganado ? "bg-red-600" : ""}`}>Estadísticas de equipo: </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos de pérdida: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos en pintura: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosEnPintura}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos segunda oportunidad: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosSegundaOport}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos contraataque: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosContraataque}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos del banco: </span>
                                    <span className="px-1">{data.totalAguadaStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor ventaja: </span>
                                    <span className="px-1">{data.totalAguadaStats.mayorVentaja}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor racha: </span>
                                    <span className="px-1">{data.totalAguadaStats.mayorRacha}</span>
                                </div>
                            </div>
                            : null :
                        data.totalAdversarioStats.puntosDePerdida ?
                            <div className="bg-gray-100 mt-4 p-4 md:text-center rounded-md shadow-md">
                                <div className={`p-2 mr-4 md:mr-0 rounded-md text-white float-left ${data.local && data.ganado ? "bg-red-600" :
                                    !data.local && data.ganado ? "bg-green-600" :
                                        data.local && !data.ganado ? "bg-green-600" :
                                            !data.local && !data.ganado ? "bg-red-600" : ""}`}>Estadísticas de equipo: </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos de pérdida: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos en pintura: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosEnPintura}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos segunda oportunidad: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosSegundaOport}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos contraataque: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosContraataque}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Puntos del banco: </span>
                                    <span className="px-1">{data.totalAdversarioStats.puntosDePerdida}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor ventaja: </span>
                                    <span className="px-1">{data.totalAdversarioStats.mayorVentaja}</span>
                                </div>
                                <div className="float-none inline-block pr-6">
                                    <span className="font-semibold">Mayor racha: </span>
                                    <span className="px-1">{data.totalAdversarioStats.mayorRacha}</span>
                                </div>
                            </div> : null
                    }

                    {/* Leyenda */}
                    {data.aguadaStats.length > 0 ?
                        <div className="bg-gray-100 mt-8 mb-4 mx-3 p-4 text-sm md:text-center rounded-md shadow-md">
                            <div className="p-2 mr-4 md:mr-0 rounded-md float-left font-semibold underline w-full text-center">Leyenda: </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">Min: </span>
                                <span className="px-1">Minutos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">Pts: </span>
                                <span className="px-1">Puntos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">LC: </span>
                                <span className="px-1">Tiros de campo</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">LC%: </span>
                                <span className="px-1">Porcentaje tiros de campo</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">2PTS: </span>
                                <span className="px-1">Dobles</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">2PTS%: </span>
                                <span className="px-1">Porcentaje dobles</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">3PTS: </span>
                                <span className="px-1">Triples</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">3PTS%: </span>
                                <span className="px-1">Porcentaje triples</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">1PTS: </span>
                                <span className="px-1">Libres</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">1PTS%: </span>
                                <span className="px-1">Porcentaje libres</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">RO: </span>
                                <span className="px-1">Rebotes ofensivos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">RD: </span>
                                <span className="px-1">Rebotes defensivos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">RT: </span>
                                <span className="px-1">Rebotes totales</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">AS: </span>
                                <span className="px-1">Asistencias</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">PER: </span>
                                <span className="px-1">Pérdidas</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">ROB: </span>
                                <span className="px-1">Robos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">BLQ: </span>
                                <span className="px-1">Bloqueos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">BR: </span>
                                <span className="px-1">Bloqueos Recibidos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">FP: </span>
                                <span className="px-1">Fouls personales</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">FR: </span>
                                <span className="px-1">Fouls recibidos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">+/-: </span>
                                <span className="px-1">Más menos</span>
                            </div>
                            <div className="float-none inline-block pr-6">
                                <span className="font-semibold">EF: </span>
                                <span className="px-1">Eficiencia</span>
                            </div>
                        </div>
                        : null}
                    {data.totalAguadaStats.totalPuntos < 21 ?
                        <div className="bg-gray-100 mt-8 mb-4 mx-3 p-4 text-sm md:text-center rounded-md shadow-md">Partido suspendido</div>
                        : null}
                    {data.aguadaStats.length === 0 && data.totalAguadaStats.totalPuntos > 21 ?
                        <div className="bg-gray-100 mt-8 mb-4 mx-3 p-4 text-sm md:text-center rounded-md shadow-md">Estadísticas no recopiladas</div>
                        : null}
                </div>
                :
                <div className="backdrop-blur-xl bg-white/20 p-10 rounded-md mb-8">
                    <ReactLoading type="bars" color="#15803d" width={'10%'} className="text-center m-auto mb-16" />
                    <p className="text-center text-white text-lg font-semibold mt-4">Buscando estadísticas...</p>
                </div>
            }

            <Footer></Footer>

        </div>
    );
}

export default Partido;
