import { Routes, Route } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import { fadeInDownBig } from "react-animations";
import Home from './components/home';
import Navbar from './components/navbar'
import BuscarPartido from './components/buscarpartido';
import Partido from './components/partido';
import Jugador from './components/jugador';
import Planteles from './components/planteles';
import Records from './components/records';
import NotFound from './components/notfound';

const SlideInDown = styled.div`animation: 1.5s ${keyframes`${fadeInDownBig}`};`;

function App() {
  return (
    <>


      <Routes>
        <Route path='/' element={
          <>
            <SlideInDown>
              <Navbar />
            </SlideInDown>
            <Home />
          </>
        } />
        <Route path='buscarpartido' element={
          <>
            <Navbar />
            <BuscarPartido />
          </>
        } />
        <Route path='partido/:partidoId' element={
          <>
            <Navbar />
            <Partido />
          </>
        } />
        <Route path='jugador/:nombreJugador' element={
          <>
            <Navbar />
            <Jugador />
          </>
        } />
        <Route path='planteles' element={
          <>
            <Navbar />
            <Planteles />
          </>
        } />
        <Route path='records' element={
          <>
            <Navbar />
            <Records />
          </>
        } />

        <Route path='*' element={<NotFound />} />

      </Routes>
    </>
  );
}

export default App;
