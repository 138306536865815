import { useState } from "react";
import escudo from '../images/escudo.png';
import close from '../images/close.png';
import menu from '../images/menu.png';
import { NavLink } from "react-router-dom";

export const navLinks = [
    {
        id: "buscarpartido",
        title: "Partidos",
    },
    {
        id: "planteles",
        title: "Planteles",
    },
    {
        id: "records",
        title: "Récords",
    },
];

const Navbar = () => {
    const [active, setActive] = useState("Home");
    const [toggle, setToggle] = useState(false);
    
    function clickedModal(e) {
        e.preventDefault();
        setToggle(toggle => !toggle);
    }

    return (
        <nav className="absolute w-full flex items-center py-2 bg-transparent z-10">
            {/* Logo */}
            <NavLink to='/' className="text-white hover:font-bold group transition duration-300">
                <img src={escudo} width="70" className="ml-7" alt="Escudo de Aguada" />
                <h1 className="text-sm text-white ml-5">Aguada Stats</h1>
                <span className="block max-w-0 ml-5 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" id="active"></span>
            </NavLink>

            {/* Desktop Navigation */}
            <ul className="list-none sm:flex hidden justify-end items-center flex-1 mr-5">
                {navLinks.map((nav, index) => (
                    <li
                        key={nav.id}
                        className={`font-poppins font-normal cursor-pointer text-[16px] ${active === nav.title ? "text-white" : "text-dimWhite"
                            } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
                        onClick={() => setActive(nav.title)}
                    >
                        <NavLink to={`/${nav.id}`} className="text-white hover:font-bold group transition duration-300">{nav.title}
                            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" id="active"></span>
                        </NavLink>
                    </li>
                ))}
            </ul>

            {/* Mobile Navigation */}
            <div className="sm:hidden flex flex-1 justify-end items-center">
                <img
                    src={toggle ? close : menu}
                    alt="menu"
                    className="w-[35px] h-[35px] z-20 object-contain mr-5"
                    onClick={() => setToggle(!toggle)}
                />

                {/* Sidebar */}
                <div id="myModal" className={`${!toggle ? "hidden" : "block"} modal`} onClick={clickedModal}>
                    <div
                        className={`${!toggle ? "hidden" : "flex"
                            } p-4 bg-black-gradient absolute top-10 right-0 mx-4 my-2 min-w-[140px] rounded-xl border-2 border-black sidebar bg-gradient-to-l from-white/90 to-white/70 mt-4`}
                    >
                        <ul className="list-none flex justify-end items-end flex-1 flex-col">
                            {navLinks.map((nav, index) => (
                                <li
                                    key={nav.id}
                                    className={`font-poppins font-medium cursor-pointer text-[16px] w-full text-right ${active === nav.title ? "text-white" : "text-dimWhite"
                                        } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                                    onClick={() => setActive(nav.title)}
                                >
                                    <NavLink to={`/${nav.id}`} className="text-black w-full block">{nav.title}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

