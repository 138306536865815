import React, { useRef, useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Footer from "./footer";

function BuscarPartido() {

    const [data, setData] = useState({ data: [] });
    const [ligas, setLigas] = useState(['']);
    const etapas = ['Temporada Regular', 'Super Liga', 'Liguilla', 'Reclasificatorio', 'Playoffs', 'Play-in', 'Cuartos de final', 'Semifinal', 'Final']
    const [canchas, setCanchas] = useState(['']);
    const [adversarios, setAdversarios] = useState(['']);
    const localias = ['Local', 'Visitante'];
    const resultados = ['Ganado', 'Perdido'];
    const alargues = ['Si', 'No'];
    const [isLoading, setIsLoading] = useState(false);
    var [jugados, setJugados] = useState(0);
    var [ganados, setGanados] = useState(0);
    var [perdidos, setPerdidos] = useState(0);

    // const torneo = useRef(null);
    const [torneo, setTorneo] = useState({ data: "" });
    const [etapa, setEtapa] = useState({ data: "" });
    const [cancha, setCancha] = useState({ data: "" });
    const [adversario, setAdversario] = useState({ data: "" });
    const [localia, setLocalia] = useState({ data: "" });
    const [resultado, setResultado] = useState({ data: "" });
    const [alargue, setAlargue] = useState({ data: "" });
    const fecha = useRef(null);

    useEffect(() => {
        Promise.all([
            fetch('https://aguada-stats-api.vercel.app/busqueda/ligas'),
            fetch('https://aguada-stats-api.vercel.app/busqueda/canchas'),
            fetch('https://aguada-stats-api.vercel.app/busqueda/adversarios')
        ])
            .then(([resLigas, resCanchas, resAdversarios]) =>
                Promise.all([resLigas.json(), resCanchas.json(), resAdversarios.json()])
            )
            .then(([dataLigas, dataCanchas, dataAdversarios]) => {
                setLigas(dataLigas);
                setCanchas(dataCanchas);
                setAdversarios(dataAdversarios);
            });
        document.title = 'Buscar partido';
    }, []);

    const setDataPartidos = (data) => {
        ganados = 0;
        perdidos = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].ganado) ganados++;
            if (!data[i].ganado) perdidos++;
        }
        setJugados(data.length);
        setGanados(ganados);
        setPerdidos(perdidos);
    };

    const buscarPartido = async () => {
        setIsLoading(true);

        let url = 'https://aguada-stats-api.vercel.app/busqueda';

        if (torneo.data !== '' && torneo !== '' || etapa.data !== '' && etapa !== '' || cancha.data !== '' && cancha !== '' ||
            adversario.data !== '' && adversario !== '' || localia.data !== '' && localia !== '' || resultado.data !== '' && resultado !== '' ||
            alargue.data !== '' && alargue !== ''
        //  || fecha.current.value !== ""
            ) url = url + "?";
        if (torneo.data !== '' && torneo !== '') url = url + "liga=" + torneo + "&";
        if (etapa.data !== '' && etapa !== '') {
            switch (etapa) {
                case "Temporada Regular":
                    url = url + "temporadaRegular=true&";
                    break;
                case "Super Liga":
                    url = url + "superLiga=true&";
                    break;
                case "Liguilla":
                    url = url + "liguilla=true&";
                    break;
                case "Reclasificatorio":
                    url = url + "reclasificatorio=true&";
                    break;
                case "Playoffs":
                    url = url + "playoffs=true&";
                    break;
                case "Play-in":
                    url = url + "playoffsEtapa=Play-in&";
                    break;
                case "Cuartos de final":
                    url = url + "playoffsEtapa=Cuartos de final&";
                    break;
                case "Semifinal":
                    url = url + "playoffsEtapa=Semifinal&";
                    break;
                case "Final":
                    url = url + "playoffsEtapa=Final&";
                    break;
                default:
                    break;
            }
        }
        if (cancha.data !== '' && cancha !== '') url = url + "cancha=" + cancha + "&";
        if (adversario.data !== '' && adversario !== '') url = url + "adversario=" + adversario + "&";
        if (localia.data !== '' && localia !== "Localía") {
            url = url + "local=" + (localia === "Local" ? "true" : "false") + "&";
        }
        if (resultado.data !== '' && resultado !== "Resultado") {
            url = url + "ganado=" + (resultado === "Ganado" ? "true" : "false") + "&";
        }
        if (alargue.data !== '' && resultado !== '') {
            url = url + "alargue=" + (alargue === "Si" ? "true" : "false") + "&";
        }
        // if (fecha.current.value !== "") url = url + "fecha=" + fecha.current.value + "&";

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            const result = await response.json();
            setData(result);
            setDataPartidos(result);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-20 pt-6">
            <h1 className="text-center mt-14 mb-8 text-3xl text-white font-semibold">Buscar partido</h1>
            {/* Buscar partidos */}
            <div className="backdrop-blur-md bg-white/50 flex flex-auto flex-col md:flex-row inherit mb-5 rounded-lg">
                {/* Filtros */}
                <div className="bg-white text-sm w-full md:w-1/4 p-3 rounded-tl-md rounded-bl-md">
                    <Autocomplete
                        id="input-torneos"
                        options={ligas}
                        onChange={(event, value) => setTorneo(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Torneo" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-etapas"
                        options={etapas}
                        onChange={(event, value) => setEtapa(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Etapa" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-canchas"
                        options={canchas}
                        onChange={(event, value) => setCancha(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Cancha" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-adversario"
                        options={adversarios}
                        onChange={(event, value) => setAdversario(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Adversario" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-localia"
                        options={localias}
                        onChange={(event, value) => setLocalia(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Localía" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-resultado"
                        options={resultados}
                        onChange={(event, value) => setResultado(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Resultado" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    <Autocomplete
                        id="input-alargue"
                        options={alargues}
                        onChange={(event, value) => setAlargue(event.target.textContent)}
                        renderInput={(params) => (
                            <TextField {...params} label="Alargue" className="bg-green-100 w-full rounded-lg" />
                        )}
                        className="w-full shadow-md mb-3"
                    />
                    {/* <input ref={fecha} type="date" className="bg-green-100 w-full shadow-md py-1 px-3 mb-4 rounded-lg text-slate-400 border-2 border-green-600 h-[2.17rem]" /> */}
                    <button className="rounded-full items-center bg-gradient-to-l from-green-800 to-green-700 text-md text-white shadow-md hover:scale-110 text-bold p-2 mt-3 block m-auto" onClick={buscarPartido}>Buscar partidos</button>
                </div>
                {/* Resultado */}
                <div className={`text-sm max-h-[29rem] w-full h-fit md:w-3/4 ${data.data ? "" : "overflow-scroll"}`}>
                    {isLoading &&
                        <div className="absolute contents md:flex backdrop-blur-xl bg-white/20 w-[-webkit-fill-available] h-[-webkit-fill-available] rounded-tr-md rounded-br-md">
                            <div className="m-auto rounded-tr-md rounded-br-md table">
                                <ReactLoading type="bars" color="#15803d" width={'50%'} className="text-center m-auto mb-5" />
                                <p className="text-center text-lg font-semibold">Buscando partidos...</p>
                            </div>
                        </div>
                    }
                    {data.length > 0 ?
                        <>
                            <table className='w-full p-0 block rounded-tr-md rounded-br-md'>
                                <thead>
                                    <tr className='text-bold bg-gray-400'>
                                        <th className="w-12 px-6">#</th>
                                        <th className="w-28 px-6">Torneo</th>
                                        <th className="w-28 px-6">Etapa</th>
                                        <th className="w-20 px-6">Partido</th>
                                        <th className="w-28 px-6">Cancha</th>
                                        <th className="w-28 px-6">Rival</th>
                                        <th className="w-24 px-6">Local</th>
                                        <th className="w-24 px-6">Resultado</th>
                                        <th className="w-24 px-6">Alargue</th>
                                        <th className="w-28 px-6">Fecha</th>
                                        <th className="w-24 px-16"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((busqueda, index) =>
                                        <>
                                            <tr className='text-center bg-gray-100 hover:bg-gray-300 border border-red-700'>
                                                <td className="border border-r-black">#{index + 1}</td>
                                                <td className="border border-r-black">{busqueda.liga}</td>

                                                <td className="border border-r-black">
                                                    {busqueda.playoffsEtapa ? busqueda.playoffsEtapa :
                                                        busqueda.temporadaRegular ? "Temp. Regular" :
                                                            busqueda.superLiga ? "Super Liga" :
                                                                busqueda.liguilla ? "Liguilla" :
                                                                    busqueda.reclasificatorio ? "Reclasificatorio" :
                                                                        busqueda.internacional ? busqueda.fase : ""}
                                                </td>
                                                <td className="border border-r-black">{busqueda.fechaLiga}</td>
                                                <td className="border border-r-black">{busqueda.cancha}</td>
                                                <td className="border border-r-black">{busqueda.adversario}</td>
                                                <td className="border border-r-black">{busqueda.local ? "Local" : "Visitante"}</td>
                                                <td className="border border-r-black">
                                                    {busqueda.ganado && busqueda.local ? "G " + busqueda.totalAguadaStats.totalPuntos + "-" + busqueda.totalAdversarioStats.totalPuntos :
                                                        busqueda.ganado && !busqueda.local ? "G " + busqueda.totalAdversarioStats.totalPuntos + "-" + busqueda.totalAguadaStats.totalPuntos :
                                                            !busqueda.ganado && busqueda.local ? "P " + busqueda.totalAguadaStats.totalPuntos + "-" + busqueda.totalAdversarioStats.totalPuntos :
                                                                !busqueda.ganado && !busqueda.local ? "P " + busqueda.totalAdversarioStats.totalPuntos + "-" + busqueda.totalAguadaStats.totalPuntos : ""}
                                                </td>
                                                <td className="border border-r-black">{busqueda.alargue ? "Si" : "No"}</td>
                                                <td className="border border-r-black">{moment(busqueda.fecha).format('DD/MM/YYYY')}</td>
                                                <td className="border border-r-black"><a href={`partido/${busqueda._id}`} className="underline">Estadísticas</a></td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </>
                        : <p className="text-center pt-5 font-semibold">No hay partidos para mostrar</p>}
                </div>
            </div>
            <div className="flex flex-auto text-center mb-4 text-white">
                <div className="w-1/3 sm:text-6xl text-4xl">
                    <h1 className="text-4xl">{jugados}</h1>
                    <h1 className="text-xl">Jugados</h1>
                </div>
                <div className="w-1/3 sm:text-6xl text-4xl">
                    <h1 className="text-4xl">{ganados}</h1>
                    <h1 className="text-xl">Ganados</h1>
                </div>
                <div className="w-1/3 sm:text-6xl text-4xl">
                    <h1 className="text-4xl">{perdidos}</h1>
                    <h1 className="text-xl">Perdidos</h1>
                </div>
            </div>

            <Footer></Footer>

        </div>
    );
}

export default BuscarPartido;
