import React, { useEffect } from "react";
import Footer from "./footer";
import star from "../images/star.png";
import silver from "../images/silver.png";
import bronze from "../images/bronze.png";
import Collapsible from 'react-collapsible';

function Planteles() {

    useEffect(() => {
        document.title = 'Planteles';
    }, []);

    const torneos = [
        (<div className="md:flex items-center h-fit mb-4">
            <div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LUB 2009-10</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">9°</div>
        </div>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2010-11</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Semifinal</p>
                <p className="text-sm cellphone:text-base font-normal">(0-3 vs BIG)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2011-12</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Semifinal</p>
                <p className="text-sm cellphone:text-base font-normal">(2-3 vs HEB)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2012-13</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={star} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">8°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">CAMPEÓN</p>
                    <p className="text-sm cellphone:text-base font-normal">(4-3 vs DSC)</p>
                </div>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2013-14</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Semifinal</p>
                <p className="text-sm cellphone:text-base font-normal">(1-3 vs DSC)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[70%] md:-translate-y-0">LSB 2013</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={silver} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">2°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">Segundo</p>
                    <p className="text-sm cellphone:text-base font-normal">(vs UNI)</p>
                </div>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[70%] md:-translate-y-0">LDA 2014</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={bronze} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">3°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">Tercero</p>
                    <p className="text-sm cellphone:text-base font-normal">(vs HAL)</p>
                </div>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2014-15</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Cuartos de final</p>
                <p className="text-sm cellphone:text-base font-normal">(1-3 vs HEB)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LSB 2014</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Fase de grupos</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2015-16</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Play-in</p>
                <p className="text-sm cellphone:text-base font-normal">(1-2 vs WEL)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2016-17</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Final</p>
                <p className="text-sm cellphone:text-base font-normal">(3-4 vs HEB)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LDA 2017</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Fase de grupos</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2017-18</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Final</p>
                <p className="text-sm cellphone:text-base font-normal">(3-4 vs MAL)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LSB 2017</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Grupo Semifinal</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LSB 2018</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Fase de grupos</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2018-19</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={star} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">9°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">CAMPEÓN</p>
                    <p className="text-sm cellphone:text-base font-normal">(4-3 vs MAL)</p>
                </div>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2019-20</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={star} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">10°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">CAMPEÓN</p>
                    <p className="text-sm cellphone:text-base font-normal">(3-1 vs TRO)</p>
                </div>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">BCLA 2019-20</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Fase de grupos</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2021</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Play-in</p>
                <p className="text-sm cellphone:text-base font-normal">(1-2 vs CAP)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2021-22</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Semifinal</p>
                <p className="text-sm cellphone:text-base font-normal">(0-3 vs PEÑ)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg">LSB 2022</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg px-4">Cuartos de final</div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2022-23</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <p className="text-base cellphone:text-lg px-4">Play-in</p>
                <p className="text-sm cellphone:text-base font-normal">(1-3 vs PEÑ)</p>
            </div></>),
        (<><div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-base cellphone:text-lg -translate-y-[50%] md:-translate-y-0">LUB 2023-24</div>
            <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center">
                <div className="relative text-center cursor-default text-black w-fit m-auto inline-block">
                    <img src={star} alt="Estrella" className="w-8 cellphone:w-10" />
                    <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-sm cellphone:text-lg font-semibold">11°</div>
                </div>
                <div className="inline-block">
                    <p className="text-base cellphone:text-lg px-2">CAMPEÓN</p>
                    <p className="text-sm cellphone:text-base font-normal">(4-1 vs PEÑ)</p>
                </div>
            </div></>)
    ]

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-20 pt-6">
            <h1 className="text-center mt-14 mb-8 text-3xl text-white font-semibold">Planteles</h1>
            <div className="backdrop-blur-md bg-gradient-to-l from-white/70 to-white/60 inherit mb-5 py-5 px-2 laptop:p-5 rounded-lg">

                <div className="block laptop:hidden">
                    <div className="md:flex items-center h-fit mb-4">
                        <div className="inline-block w-2/5 md:w-1/6 font-semibold text-center text-lg cellphone:text-xl underline">Torneo</div>
                        <div className="inline-block w-3/5 md:w-1/6 font-semibold text-center text-lg cellphone:text-xl underline">Posición</div>
                        <div className="hidden md:inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl underline" style={{ width: '65%' }}>Plantel</div>
                    </div>
                    <Collapsible trigger={torneos[0]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Adrián Laborda`} className="hover:text-slate-500 hover:underline">Adrián Laborda</a><span>, </span>
                                <a href={`../jugador/Enrique Elhordoy`} className="hover:text-slate-500 hover:underline">Enrique Elhordoy</a><span>, </span>
                                <a href={`../jugador/Francisco Cabrera`} className="hover:text-slate-500 hover:underline">Francisco Cabrera</a><span>, </span>
                                <a href={`../jugador/Rodrigo Carvidón`} className="hover:text-slate-500 hover:underline">Rodrigo Carvidón</a><span>, </span>
                                <a href={`../jugador/Santiago Da Rosa`} className="hover:text-slate-500 hover:underline">Santiago Da Rosa</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Jessan Gray`} className="hover:text-slate-500 hover:underline">Jessan Gray</a><span>, </span>
                                <a href={`../jugador/Justin Leith`} className="hover:text-slate-500 hover:underline">Justin Leith (cortado)</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Oleaurre`} className="hover:text-slate-500 hover:underline">Andrés Oleaurre</a><span>, </span>
                                <a href={`../jugador/Andrés Stekl`} className="hover:text-slate-500 hover:underline">Andrés Stekl</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Liber Menéndez`} className="hover:text-slate-500 hover:underline">Liber Menéndez</a><span>, </span>
                                <a href={`../jugador/Marcelo Stekl`} className="hover:text-slate-500 hover:underline">Marcelo Stekl</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Juan Carlos Werstein`} className="hover:text-slate-500 hover:underline">Juan Carlos Werstein</a></p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[1]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Alfonso González`} className="hover:text-slate-500 hover:underline">Alfonso González</a><span>, </span>
                                <a href={`../jugador/Hugo Timote`} className="hover:text-slate-500 hover:underline">Hugo Timote (draft)</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Carvidón`} className="hover:text-slate-500 hover:underline">Rodrigo Carvidón</a><span>, </span>
                                <a href={`../jugador/Rodrigo Riera`} className="hover:text-slate-500 hover:underline">Rodrigo Riera</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Benjamin Puckett`} className="hover:text-slate-500 hover:underline">Benjamin Puckett</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Oleaurre`} className="hover:text-slate-500 hover:underline">Andrés Oleaurre</a><span>, </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Marcelo Stekl`} className="hover:text-slate-500 hover:underline">Marcelo Stekl</a><span>, </span>
                                <a href={`../jugador/Sebastián Dolhagaray`} className="hover:text-slate-500 hover:underline">Sebastián Dolhagaray</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Álvaro Tito`} className="hover:text-slate-500 hover:underline">Álvaro Tito</a></p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[2]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Alejandro Pérez`} className="hover:text-slate-500 hover:underline">Alejandro Pérez (draft)</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig (nacionalizado)</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Gonzalo Balbuena`} className="hover:text-slate-500 hover:underline">Gonzalo Balbuena (draft)</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Billy McShepard`} className="hover:text-slate-500 hover:underline">Billy McShepard (cortado)</a><span>, </span>
                                <a href={`../jugador/Devin Davis`} className="hover:text-slate-500 hover:underline">Devin Davis (cortado)</a><span>, </span>
                                <a href={`../jugador/Justin Rutty`} className="hover:text-slate-500 hover:underline">Justin Rutty (cortado)</a><span>, </span>
                                <a href={`../jugador/Kenneth Walker`} className="hover:text-slate-500 hover:underline">Kenneth Walker (cortado)</a><span>, </span>
                                <a href={`../jugador/Phillip Jones`} className="hover:text-slate-500 hover:underline">Phillip Jones (cortado)</a><span>, </span>
                                <a href={`../jugador/Steffphon Pettigrew`} className="hover:text-slate-500 hover:underline">Steffphon Pettigrew</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Fabián Boscardín`} className="hover:text-slate-500 hover:underline">Fabián Boscardín</a><span>, </span>
                                <a href={`../jugador/Federico Álvarez`} className="hover:text-slate-500 hover:underline">Federico Álvarez</a><span>, </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Pablo Falero`} className="hover:text-slate-500 hover:underline">Pablo Falero</a><span>, </span>
                                <a href={`../jugador/Sebastián Banquerque`} className="hover:text-slate-500 hover:underline">Sebastián Banquerque</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Marcelo Capalbo`} className="hover:text-slate-500 hover:underline">Marcelo Capalbo</a></p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[3]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Adrian Moss`} className="hover:text-slate-500 hover:underline">Adrian Moss (cortado)</a><span>, </span>
                                <a href={`../jugador/Byron Allen`} className="hover:text-slate-500 hover:underline">Byron Allen (cortado)</a><span>, </span>
                                <a href={`../jugador/Christopher Hayes`} className="hover:text-slate-500 hover:underline">Christopher Hayes (cortado)</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Shaun Pruitt`} className="hover:text-slate-500 hover:underline">Shaun Pruitt (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Julio Migliónico`} className="hover:text-slate-500 hover:underline">Julio Migliónico</a><span>, </span>
                                <a href={`../jugador/Matías Alonso`} className="hover:text-slate-500 hover:underline">Matías Alonso</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Marcelo Capalbo`} className="hover:text-slate-500 hover:underline">Marcelo Capalbo (cortado)</a><span>, </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[4]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brandon Hunter`} className="hover:text-slate-500 hover:underline">Brandon Hunter (cortado)</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[5]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brandon Hunter`} className="hover:text-slate-500 hover:underline">Brandon Hunter</a><span>, </span>
                                <a href={`../jugador/Cedric McGowan`} className="hover:text-slate-500 hover:underline">Cedric McGowan</a><span>, </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kevin Palacios`} className="hover:text-slate-500 hover:underline">Kevin Palacios</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[6]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Joaquín Izuibejeres`} className="hover:text-slate-500 hover:underline">Joaquín Izuibejeres</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Santiago Vidal`} className="hover:text-slate-500 hover:underline">Santiago Vidal</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Malcom White`} className="hover:text-slate-500 hover:underline">Malcom White</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a><span>, </span>
                                <a href={`../jugador/Fernando Medina`} className="hover:text-slate-500 hover:underline">Fernando Medina</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[7]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Martín Osimani`} className="hover:text-slate-500 hover:underline">Martín Osimani</a><span>, </span>
                                <a href={`../jugador/Nicolás Borsellino`} className="hover:text-slate-500 hover:underline">Nicolás Borsellino</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brian Cook`} className="hover:text-slate-500 hover:underline">Brian Cook</a><span>, </span>
                                <a href={`../jugador/Chris Moss`} className="hover:text-slate-500 hover:underline">Chris Moss</a><span>, </span>
                                <a href={`../jugador/Marcus Elliott`} className="hover:text-slate-500 hover:underline">Marcus Elliott (cortado)</a><span>, </span>
                                <a href={`../jugador/Marcus Goree`} className="hover:text-slate-500 hover:underline">Marcus Goree (cortado)</a><span>, </span>
                                <a href={`../jugador/Robert Hornsby`} className="hover:text-slate-500 hover:underline">Robert Hornsby (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 25: </span>
                                <a href={`../jugador/Rogelio de León`} className="hover:text-slate-500 hover:underline">Rogelio de León</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Lucas Rodríguez`} className="hover:text-slate-500 hover:underline">Lucas Rodríguez</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Germán Fernández`} className="hover:text-slate-500 hover:underline">Germán Fernández</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[8]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Joaquín Izuibejeres`} className="hover:text-slate-500 hover:underline">Joaquín Izuibejeres</a><span>, </span>
                                <a href={`../jugador/Nicolás Borsellino`} className="hover:text-slate-500 hover:underline">Nicolás Borsellino</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Timothy Jennings`} className="hover:text-slate-500 hover:underline">Timothy Jennings (nacionalizado)</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Chris Moss`} className="hover:text-slate-500 hover:underline">Chris Moss</a><span>, </span>
                                <a href={`../jugador/Robert Hornsby`} className="hover:text-slate-500 hover:underline">Robert Hornsby</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Germán Fernández`} className="hover:text-slate-500 hover:underline">Germán Fernández</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[9]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/José Mackiewicz`} className="hover:text-slate-500 hover:underline">José Mackiewicz</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Timothy Jennings`} className="hover:text-slate-500 hover:underline">Timothy Jennings (nacionalizado)</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Alex Franklin`} className="hover:text-slate-500 hover:underline">Alex Franklin (cortado)</a><span>, </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley (cortado)</a><span>, </span>
                                <a href={`../jugador/Chris Massie`} className="hover:text-slate-500 hover:underline">Chris Massie</a><span>, </span>
                                <a href={`../jugador/John De Groat`} className="hover:text-slate-500 hover:underline">John De Groat</a><span>, </span>
                                <a href={`../jugador/Marcus Melvin`} className="hover:text-slate-500 hover:underline">Marcus Melvin (cortado)</a><span>, </span>
                                <a href={`../jugador/Rashad Bell`} className="hover:text-slate-500 hover:underline">Rashad Bell (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Curro Segura`} className="hover:text-slate-500 hover:underline">Curro Segura</a><span>, </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[10]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Gustavo Barrera`} className="hover:text-slate-500 hover:underline">Gustavo Barrera</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Jordan Henriquez`} className="hover:text-slate-500 hover:underline">Jordan Henriquez (cortado)</a><span>, </span>
                                <a href={`../jugador/Zvonko Buljan`} className="hover:text-slate-500 hover:underline">Zvonko Buljan (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Edison Espinosa`} className="hover:text-slate-500 hover:underline">Edison Espinosa</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[11]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Haller`} className="hover:text-slate-500 hover:underline">Federico Haller</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Gustavo Barrera`} className="hover:text-slate-500 hover:underline">Gustavo Barrera</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[12]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis (cortado)</a><span>, </span>
                                <a href={`../jugador/Jamario Moon`} className="hover:text-slate-500 hover:underline">Jamario Moon (cortado)</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Federico Chollet`} className="hover:text-slate-500 hover:underline">Federico Chollet</a><span>, </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[13]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Haller`} className="hover:text-slate-500 hover:underline">Federico Haller</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Hernando Cáceres`} className="hover:text-slate-500 hover:underline">Hernando Cáceres</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Anthony Danridge`} className="hover:text-slate-500 hover:underline">Anthony Danridge</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[14]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro Taboada`} className="hover:text-slate-500 hover:underline">Leandro Taboada</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Ricardo Glenn`} className="hover:text-slate-500 hover:underline">Ricardo Glenn</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[15]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro Taboada`} className="hover:text-slate-500 hover:underline">Leandro Taboada</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Bryan Davis`} className="hover:text-slate-500 hover:underline">Bryan Davis (cortado)</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Ricardo Glenn`} className="hover:text-slate-500 hover:underline">Ricardo Glenn (cortado)</a><span>, </span>
                                <a href={`../jugador/Zach Graham`} className="hover:text-slate-500 hover:underline">Zach Graham</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Alfonso Arrillaga`} className="hover:text-slate-500 hover:underline">Alfonso Arrillaga</a><span>, </span>
                                <a href={`../jugador/Ignacio Núñez`} className="hover:text-slate-500 hover:underline">Ignacio Núñez</a><span>, </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Leonardo Margosian`} className="hover:text-slate-500 hover:underline">Leonardo Margosian</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[16]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Courtney Fells`} className="hover:text-slate-500 hover:underline">Courtney Fells (cortado)</a><span>, </span>
                                <a href={`../jugador/Darryl Watkins`} className="hover:text-slate-500 hover:underline">Darryl Watkins (cortado)</a><span>, </span>
                                <a href={`../jugador/David Doblas`} className="hover:text-slate-500 hover:underline">David Doblas (cortado)</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a><span>, </span>
                                <a href={`../jugador/Robert Battle`} className="hover:text-slate-500 hover:underline">Robert Battle (cortado)</a><span>, </span>
                                <a href={`../jugador/Weyinmi Rose`} className="hover:text-slate-500 hover:underline">Weyinmi Rose (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 25: </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Agustín Viotti`} className="hover:text-slate-500 hover:underline">Agustín Viotti</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Joaquín Silveira`} className="hover:text-slate-500 hover:underline">Joaquín Silveira</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a><span>, </span>
                                <a href={`../jugador/Adrián Capelli`} className="hover:text-slate-500 hover:underline">Adrián Capelli</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[17]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Emilio Taboada`} className="hover:text-slate-500 hover:underline">Emilio Taboada</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Mauricio Aguiar`} className="hover:text-slate-500 hover:underline">Mauricio Aguiar</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Courtney Fells`} className="hover:text-slate-500 hover:underline">Courtney Fells</a><span>, </span>
                                <a href={`../jugador/Darryl Watkins`} className="hover:text-slate-500 hover:underline">Darryl Watkins</a><span>, </span>
                                <a href={`../jugador/David Doblas`} className="hover:text-slate-500 hover:underline">David Doblas</a><span>, </span>
                                <a href={`../jugador/Jarrett Croff`} className="hover:text-slate-500 hover:underline">Jarrett Croff</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a><span>, </span>
                                <a href={`../jugador/Tony Mitchell`} className="hover:text-slate-500 hover:underline">Tony Mitchell</a><span>, </span>
                                <a href={`../jugador/Weyinmi Rose`} className="hover:text-slate-500 hover:underline">Weyinmi Rose</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[18]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Diego Soarez`} className="hover:text-slate-500 hover:underline">Diego Soarez</a><span>, </span>
                                <a href={`../jugador/Emilio Taboada`} className="hover:text-slate-500 hover:underline">Emilio Taboada</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Adrián Capelli`} className="hover:text-slate-500 hover:underline">Adrián Capelli</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[19]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Mathías Calfani`} className="hover:text-slate-500 hover:underline">Mathías Calfani</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Avry Holmes`} className="hover:text-slate-500 hover:underline">Avry Holmes (cortado)</a><span>, </span>
                                <a href={`../jugador/Chad Posthumus`} className="hover:text-slate-500 hover:underline">Chad Posthumus (cortado)</a><span>, </span>
                                <a href={`../jugador/Jamari Traylor`} className="hover:text-slate-500 hover:underline">Jamari Traylor (cortado)</a><span>, </span>
                                <a href={`../jugador/Jarvis Varnado`} className="hover:text-slate-500 hover:underline">Jarvis Varnado (cortado)</a><span>, </span>
                                <a href={`../jugador/José Ascanio`} className="hover:text-slate-500 hover:underline">José Ascanio</a><span>, </span>
                                <a href={`../jugador/Marcos Mata`} className="hover:text-slate-500 hover:underline">Marcos Mata</a><span>, </span>
                                <a href={`../jugador/Martin Krampelj`} className="hover:text-slate-500 hover:underline">Martin Krampelj</a><span>, </span>
                                <a href={`../jugador/Sebastián Vega`} className="hover:text-slate-500 hover:underline">Sebastián Vega</a><span>, </span>
                                <a href={`../jugador/Tyreek Duren`} className="hover:text-slate-500 hover:underline">Tyreek Duren</a><span>, </span>
                                <a href={`../jugador/Tyrone Lee`} className="hover:text-slate-500 hover:underline">Tyrone Lee (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Joaquín Silveira`} className="hover:text-slate-500 hover:underline">Joaquín Silveira</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Amarillo`} className="hover:text-slate-500 hover:underline">Mateo Amarillo</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Daniel Seoane`} className="hover:text-slate-500 hover:underline">Daniel Seoane (cortado)</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Nicolás Casalánguida`} className="hover:text-slate-500 hover:underline">Nicolás Casalánguida</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[20]} className="text-center text-xl font-semibold mt-6"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Facundo Medina</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Theo Metzger`} className="hover:text-slate-500 hover:underline">Theo Metzger</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Eloy Vargas</a><span>, </span>
                                <a href={`../jugador/Jordan Adams`} className="hover:text-slate-500 hover:underline">Jordan Adams</a><span>, </span>
                                <a href={`../jugador/Kyle Fuller`} className="hover:text-slate-500 hover:underline">Kyle Fuller</a><span>, </span>
                                <a href={`../jugador/Sheldon Mac`} className="hover:text-slate-500 hover:underline">Sheldon Mac</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Luciano Planells</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Leandro Ramella`} className="hover:text-slate-500 hover:underline">Leandro Ramella</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[21]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Facundo Medina</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Leandro Cerminato`} className="hover:text-slate-500 hover:underline">Leandro Cerminato</a><span>, </span>
                                <a href={`../jugador/Theo Metzger`} className="hover:text-slate-500 hover:underline">Theo Metzger</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Anthony Young`} className="hover:text-slate-500 hover:underline">Anthony Young (cortado)</a><span>, </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Eloy Vargas</a><span>, </span>
                                <a href={`../jugador/Gelvis Solano`} className="hover:text-slate-500 hover:underline">Gelvis Solano</a><span>, </span>
                                <a href={`../jugador/Jordan Adams`} className="hover:text-slate-500 hover:underline">Jordan Adams (cortado)</a><span>, </span>
                                <a href={`../jugador/Kyle Fuller`} className="hover:text-slate-500 hover:underline">Kyle Fuller (cortado)</a><span>, </span>
                                <a href={`../jugador/Orlando Johnson`} className="hover:text-slate-500 hover:underline">Orlando Johnson (cortado)</a><span>, </span>
                                <a href={`../jugador/Raphiael Putney`} className="hover:text-slate-500 hover:underline">Raphiael Putney</a><span>, </span>
                                <a href={`../jugador/Sheldon Mac`} className="hover:text-slate-500 hover:underline">Sheldon Mac (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Felipe García`} className="hover:text-slate-500 hover:underline">Felipe García</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Luciano Planells</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Leandro Ramella`} className="hover:text-slate-500 hover:underline">Leandro Ramella</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a>
                            </p>
                        </div>
                    </Collapsible>
                    <Collapsible trigger={torneos[22]} className="text-center text-xl font-semibold mt-6 collapsible-planteles"
                        openedClassName="text-center text-xl font-semibold mt-6"
                        open={false}>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0 px-2 font-normal text-base text-left">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Diego García (Recambio)</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Diego Pena García</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Leandro Cerminato`} className="hover:text-slate-500 hover:underline">Santiago Vidal</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Donald Sims</a><span>, </span>
                                <a href={`../jugador/Gelvis Solano`} className="hover:text-slate-500 hover:underline">Luís Santos</a><span>, </span>
                                <a href={`../jugador/Raphiael Putney`} className="hover:text-slate-500 hover:underline">Victor Rudd</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Felipe García`} className="hover:text-slate-500 hover:underline">Bruno Curadossi</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Facundo Villalba</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Manuel Fernández</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Valentino Acuña</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a>
                            </p>
                        </div>
                    </Collapsible>

                </div>

                <div className="hidden laptop:block">
                    <div className="md:flex items-center px-7 h-fit mb-4">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl underline">Torneo</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl underline">Posición</div>
                        <div className="hidden md:inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl underline" style={{ width: '65%' }}>Plantel</div>
                    </div>
                    {/* LUB 2009-10 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl">LUB 2009-10</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">9°</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Adrián Laborda`} className="hover:text-slate-500 hover:underline">Adrián Laborda</a><span>, </span>
                                <a href={`../jugador/Enrique Elhordoy`} className="hover:text-slate-500 hover:underline">Enrique Elhordoy</a><span>, </span>
                                <a href={`../jugador/Francisco Cabrera`} className="hover:text-slate-500 hover:underline">Francisco Cabrera</a><span>, </span>
                                <a href={`../jugador/Rodrigo Carvidón`} className="hover:text-slate-500 hover:underline">Rodrigo Carvidón</a><span>, </span>
                                <a href={`../jugador/Santiago Da Rosa`} className="hover:text-slate-500 hover:underline">Santiago Da Rosa</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Jessan Gray`} className="hover:text-slate-500 hover:underline">Jessan Gray</a><span>, </span>
                                <a href={`../jugador/Justin Leith`} className="hover:text-slate-500 hover:underline">Justin Leith (cortado)</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Oleaurre`} className="hover:text-slate-500 hover:underline">Andrés Oleaurre</a><span>, </span>
                                <a href={`../jugador/Andrés Stekl`} className="hover:text-slate-500 hover:underline">Andrés Stekl</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Liber Menéndez`} className="hover:text-slate-500 hover:underline">Liber Menéndez</a><span>, </span>
                                <a href={`../jugador/Marcelo Stekl`} className="hover:text-slate-500 hover:underline">Marcelo Stekl</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Juan Carlos Werstein`} className="hover:text-slate-500 hover:underline">Juan Carlos Werstein</a></p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2010-11 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2010-11</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Semifinal</p>
                            <p className="text-l font-normal">(0-3 vs BIG)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Alfonso González`} className="hover:text-slate-500 hover:underline">Alfonso González</a><span>, </span>
                                <a href={`../jugador/Hugo Timote`} className="hover:text-slate-500 hover:underline">Hugo Timote (draft)</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Carvidón`} className="hover:text-slate-500 hover:underline">Rodrigo Carvidón</a><span>, </span>
                                <a href={`../jugador/Rodrigo Riera`} className="hover:text-slate-500 hover:underline">Rodrigo Riera</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Benjamin Puckett`} className="hover:text-slate-500 hover:underline">Benjamin Puckett</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Oleaurre`} className="hover:text-slate-500 hover:underline">Andrés Oleaurre</a><span>, </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Marcelo Stekl`} className="hover:text-slate-500 hover:underline">Marcelo Stekl</a><span>, </span>
                                <a href={`../jugador/Sebastián Dolhagaray`} className="hover:text-slate-500 hover:underline">Sebastián Dolhagaray</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Álvaro Tito`} className="hover:text-slate-500 hover:underline">Álvaro Tito</a></p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2011-12 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2011-12</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Semifinal</p>
                            <p className="text-l font-normal">(2-3 vs HEB)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Alejandro Pérez`} className="hover:text-slate-500 hover:underline">Alejandro Pérez (draft)</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig (nacionalizado)</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Gonzalo Balbuena`} className="hover:text-slate-500 hover:underline">Gonzalo Balbuena (draft)</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Billy McShepard`} className="hover:text-slate-500 hover:underline">Billy McShepard (cortado)</a><span>, </span>
                                <a href={`../jugador/Devin Davis`} className="hover:text-slate-500 hover:underline">Devin Davis (cortado)</a><span>, </span>
                                <a href={`../jugador/Justin Rutty`} className="hover:text-slate-500 hover:underline">Justin Rutty (cortado)</a><span>, </span>
                                <a href={`../jugador/Kenneth Walker`} className="hover:text-slate-500 hover:underline">Kenneth Walker (cortado)</a><span>, </span>
                                <a href={`../jugador/Phillip Jones`} className="hover:text-slate-500 hover:underline">Phillip Jones (cortado)</a><span>, </span>
                                <a href={`../jugador/Steffphon Pettigrew`} className="hover:text-slate-500 hover:underline">Steffphon Pettigrew</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Fabián Boscardín`} className="hover:text-slate-500 hover:underline">Fabián Boscardín</a><span>, </span>
                                <a href={`../jugador/Federico Álvarez`} className="hover:text-slate-500 hover:underline">Federico Álvarez</a><span>, </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Pablo Falero`} className="hover:text-slate-500 hover:underline">Pablo Falero</a><span>, </span>
                                <a href={`../jugador/Sebastián Banquerque`} className="hover:text-slate-500 hover:underline">Sebastián Banquerque</a>
                            </p>
                            <p><span className="font-semibold">DT: </span><a href={`../jugador/Marcelo Capalbo`} className="hover:text-slate-500 hover:underline">Marcelo Capalbo</a></p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2012-13 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[120%] md:-translate-y-0">LUB 2012-13</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={star} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">8°</div>
                            </div>
                            <p className="text-xl px-4">CAMPEÓN</p>
                            <p className="text-l font-normal">(4-3 vs DSC)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Adrian Moss`} className="hover:text-slate-500 hover:underline">Adrian Moss (cortado)</a><span>, </span>
                                <a href={`../jugador/Byron Allen`} className="hover:text-slate-500 hover:underline">Byron Allen (cortado)</a><span>, </span>
                                <a href={`../jugador/Christopher Hayes`} className="hover:text-slate-500 hover:underline">Christopher Hayes (cortado)</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Shaun Pruitt`} className="hover:text-slate-500 hover:underline">Shaun Pruitt (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gabriel Mino`} className="hover:text-slate-500 hover:underline">Gabriel Mino</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Julio Migliónico`} className="hover:text-slate-500 hover:underline">Julio Migliónico</a><span>, </span>
                                <a href={`../jugador/Matías Alonso`} className="hover:text-slate-500 hover:underline">Matías Alonso</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Marcelo Capalbo`} className="hover:text-slate-500 hover:underline">Marcelo Capalbo (cortado)</a><span>, </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2013-14 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2013-14</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Semifinal</p>
                            <p className="text-l font-normal">(1-3 vs DSC)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brandon Hunter`} className="hover:text-slate-500 hover:underline">Brandon Hunter (cortado)</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LSB 2013 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[70%] md:-translate-y-0">LSB 2013</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={silver} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">2°</div>
                            </div>
                            <p className="text-xl px-4">Segundo</p>
                            <p className="text-l font-normal">(vs UNI)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brandon Hunter`} className="hover:text-slate-500 hover:underline">Brandon Hunter</a><span>, </span>
                                <a href={`../jugador/Cedric McGowan`} className="hover:text-slate-500 hover:underline">Cedric McGowan</a><span>, </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kevin Palacios`} className="hover:text-slate-500 hover:underline">Kevin Palacios</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LDA 2014 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[70%] md:-translate-y-0">LDA 2014</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={bronze} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">3°</div>
                            </div>
                            <p className="text-xl px-4">Tercero</p>
                            <p className="text-l font-normal">(vs HAL)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Alejandro Muro`} className="hover:text-slate-500 hover:underline">Alejandro Muro</a><span>, </span>
                                <a href={`../jugador/Brian Craig`} className="hover:text-slate-500 hover:underline">Brian Craig</a><span>, </span>
                                <a href={`../jugador/Diego González`} className="hover:text-slate-500 hover:underline">Diego González</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Joaquín Izuibejeres`} className="hover:text-slate-500 hover:underline">Joaquín Izuibejeres</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Pablo Ibón`} className="hover:text-slate-500 hover:underline">Pablo Ibón</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Santiago Vidal`} className="hover:text-slate-500 hover:underline">Santiago Vidal</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Greg Dilligard`} className="hover:text-slate-500 hover:underline">Greg Dilligard</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Malcom White`} className="hover:text-slate-500 hover:underline">Malcom White</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Iván Arbildi`} className="hover:text-slate-500 hover:underline">Iván Arbildi</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a><span>, </span>
                                <a href={`../jugador/Fernando Medina`} className="hover:text-slate-500 hover:underline">Fernando Medina</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2014-15 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2014-15</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Cuartos de final</p>
                            <p className="text-l font-normal">(1-3 vs HEB)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Martín Osimani`} className="hover:text-slate-500 hover:underline">Martín Osimani</a><span>, </span>
                                <a href={`../jugador/Nicolás Borsellino`} className="hover:text-slate-500 hover:underline">Nicolás Borsellino</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Brian Cook`} className="hover:text-slate-500 hover:underline">Brian Cook</a><span>, </span>
                                <a href={`../jugador/Chris Moss`} className="hover:text-slate-500 hover:underline">Chris Moss</a><span>, </span>
                                <a href={`../jugador/Marcus Elliott`} className="hover:text-slate-500 hover:underline">Marcus Elliott (cortado)</a><span>, </span>
                                <a href={`../jugador/Marcus Goree`} className="hover:text-slate-500 hover:underline">Marcus Goree (cortado)</a><span>, </span>
                                <a href={`../jugador/Robert Hornsby`} className="hover:text-slate-500 hover:underline">Robert Hornsby (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 25: </span>
                                <a href={`../jugador/Rogelio de León`} className="hover:text-slate-500 hover:underline">Rogelio de León</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Lucas Rodríguez`} className="hover:text-slate-500 hover:underline">Lucas Rodríguez</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Germán Fernández`} className="hover:text-slate-500 hover:underline">Germán Fernández</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LSB 2014 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LSB 2014</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Fase de grupos</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Joaquín Izuibejeres`} className="hover:text-slate-500 hover:underline">Joaquín Izuibejeres</a><span>, </span>
                                <a href={`../jugador/Nicolás Borsellino`} className="hover:text-slate-500 hover:underline">Nicolás Borsellino</a><span>, </span>
                                <a href={`../jugador/Pablo Morales`} className="hover:text-slate-500 hover:underline">Pablo Morales</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Timothy Jennings`} className="hover:text-slate-500 hover:underline">Timothy Jennings (nacionalizado)</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Chris Moss`} className="hover:text-slate-500 hover:underline">Chris Moss</a><span>, </span>
                                <a href={`../jugador/Robert Hornsby`} className="hover:text-slate-500 hover:underline">Robert Hornsby</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Lucas Vassallucci`} className="hover:text-slate-500 hover:underline">Lucas Vassallucci</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Germán Fernández`} className="hover:text-slate-500 hover:underline">Germán Fernández</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2015-16 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2015-16</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Play-in</p>
                            <p className="text-l font-normal">(1-2 vs WEL)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/José Mackiewicz`} className="hover:text-slate-500 hover:underline">José Mackiewicz</a><span>, </span>
                                <a href={`../jugador/Rodrigo Trelles`} className="hover:text-slate-500 hover:underline">Rodrigo Trelles</a><span>, </span>
                                <a href={`../jugador/Timothy Jennings`} className="hover:text-slate-500 hover:underline">Timothy Jennings (nacionalizado)</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Alex Franklin`} className="hover:text-slate-500 hover:underline">Alex Franklin (cortado)</a><span>, </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley (cortado)</a><span>, </span>
                                <a href={`../jugador/Chris Massie`} className="hover:text-slate-500 hover:underline">Chris Massie</a><span>, </span>
                                <a href={`../jugador/John De Groat`} className="hover:text-slate-500 hover:underline">John De Groat</a><span>, </span>
                                <a href={`../jugador/Marcus Melvin`} className="hover:text-slate-500 hover:underline">Marcus Melvin (cortado)</a><span>, </span>
                                <a href={`../jugador/Rashad Bell`} className="hover:text-slate-500 hover:underline">Rashad Bell (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Andrés Dotti`} className="hover:text-slate-500 hover:underline">Andrés Dotti</a><span>, </span>
                                <a href={`../jugador/Gastón Romero`} className="hover:text-slate-500 hover:underline">Gastón Romero</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Curro Segura`} className="hover:text-slate-500 hover:underline">Curro Segura</a><span>, </span>
                                <a href={`../jugador/Javier Espíndola`} className="hover:text-slate-500 hover:underline">Javier Espíndola</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2016-17 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2016-17</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Final</p>
                            <p className="text-l font-normal">(3-4 vs HEB)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Gustavo Barrera`} className="hover:text-slate-500 hover:underline">Gustavo Barrera</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Jordan Henriquez`} className="hover:text-slate-500 hover:underline">Jordan Henriquez (cortado)</a><span>, </span>
                                <a href={`../jugador/Zvonko Buljan`} className="hover:text-slate-500 hover:underline">Zvonko Buljan (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Edison Espinosa`} className="hover:text-slate-500 hover:underline">Edison Espinosa</a><span>, </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LDA 2017 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LDA 2017</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Fase de grupos</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Haller`} className="hover:text-slate-500 hover:underline">Federico Haller</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Gustavo Barrera`} className="hover:text-slate-500 hover:underline">Gustavo Barrera</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gonzalo Iglesias`} className="hover:text-slate-500 hover:underline">Gonzalo Iglesias</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2017-18 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2017-18</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Final</p>
                            <p className="text-l font-normal">(3-4 vs MAL)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Dwayne Curtis`} className="hover:text-slate-500 hover:underline">Dwayne Curtis (cortado)</a><span>, </span>
                                <a href={`../jugador/Jamario Moon`} className="hover:text-slate-500 hover:underline">Jamario Moon (cortado)</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Federico Chollet`} className="hover:text-slate-500 hover:underline">Federico Chollet</a><span>, </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Luca Magnone`} className="hover:text-slate-500 hover:underline">Luca Magnone</a><span>, </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LSB 2017 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LSB 2017</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Grupo Semifinal</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Diego García`} className="hover:text-slate-500 hover:underline">Diego García</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Haller`} className="hover:text-slate-500 hover:underline">Federico Haller</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Hernando Cáceres`} className="hover:text-slate-500 hover:underline">Hernando Cáceres</a><span>, </span>
                                <a href={`../jugador/Miguel Barriola`} className="hover:text-slate-500 hover:underline">Miguel Barriola</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Anthony Danridge`} className="hover:text-slate-500 hover:underline">Anthony Danridge</a><span>, </span>
                                <a href={`../jugador/Jeremis Smith`} className="hover:text-slate-500 hover:underline">Jeremis Smith</a><span>, </span>
                                <a href={`../jugador/Kyle Lamonte`} className="hover:text-slate-500 hover:underline">Kyle Lamonte</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LSB 2018 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LSB 2018</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Fase de grupos</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro Taboada`} className="hover:text-slate-500 hover:underline">Leandro Taboada</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Ricardo Glenn`} className="hover:text-slate-500 hover:underline">Ricardo Glenn</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2018-19 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[120%] md:-translate-y-0">LUB 2018-19</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={star} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">9°</div>
                            </div>
                            <p className="text-xl px-4">CAMPEÓN</p>
                            <p className="text-l font-normal">(4-3 vs MAL)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Demian Álvarez`} className="hover:text-slate-500 hover:underline">Demian Álvarez</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro Taboada`} className="hover:text-slate-500 hover:underline">Leandro Taboada</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Andrew Feeley`} className="hover:text-slate-500 hover:underline">Andrew Feeley</a><span>, </span>
                                <a href={`../jugador/Bryan Davis`} className="hover:text-slate-500 hover:underline">Bryan Davis (cortado)</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Ricardo Glenn`} className="hover:text-slate-500 hover:underline">Ricardo Glenn (cortado)</a><span>, </span>
                                <a href={`../jugador/Zach Graham`} className="hover:text-slate-500 hover:underline">Zach Graham</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Alfonso Arrillaga`} className="hover:text-slate-500 hover:underline">Alfonso Arrillaga</a><span>, </span>
                                <a href={`../jugador/Ignacio Núñez`} className="hover:text-slate-500 hover:underline">Ignacio Núñez</a><span>, </span>
                                <a href={`../jugador/Joaquín Rodríguez`} className="hover:text-slate-500 hover:underline">Joaquín Rodríguez</a><span>, </span>
                                <a href={`../jugador/Leonardo Margosian`} className="hover:text-slate-500 hover:underline">Leonardo Margosian</a><span>, </span>
                                <a href={`../jugador/Nahuel Lemos`} className="hover:text-slate-500 hover:underline">Nahuel Lemos</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Fernando Cabrera`} className="hover:text-slate-500 hover:underline">Fernando Cabrera</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a><span>, </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2019-20 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[120%] md:-translate-y-0">LUB 2019-20</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={star} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">10°</div>
                            </div>
                            <p className="text-xl px-4">CAMPEÓN</p>
                            <p className="text-l font-normal">(3-1 vs TRO)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Courtney Fells`} className="hover:text-slate-500 hover:underline">Courtney Fells (cortado)</a><span>, </span>
                                <a href={`../jugador/Darryl Watkins`} className="hover:text-slate-500 hover:underline">Darryl Watkins (cortado)</a><span>, </span>
                                <a href={`../jugador/David Doblas`} className="hover:text-slate-500 hover:underline">David Doblas (cortado)</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a><span>, </span>
                                <a href={`../jugador/Robert Battle`} className="hover:text-slate-500 hover:underline">Robert Battle (cortado)</a><span>, </span>
                                <a href={`../jugador/Weyinmi Rose`} className="hover:text-slate-500 hover:underline">Weyinmi Rose (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 25: </span>
                                <a href={`../jugador/Martín Garay`} className="hover:text-slate-500 hover:underline">Martín Garay</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Agustín Viotti`} className="hover:text-slate-500 hover:underline">Agustín Viotti</a><span>, </span>
                                <a href={`../jugador/Ignacio Castro`} className="hover:text-slate-500 hover:underline">Ignacio Castro</a><span>, </span>
                                <a href={`../jugador/Joaquín Silveira`} className="hover:text-slate-500 hover:underline">Joaquín Silveira</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a><span>, </span>
                                <a href={`../jugador/Nahuel Santos`} className="hover:text-slate-500 hover:underline">Nahuel Santos</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a><span>, </span>
                                <a href={`../jugador/Adrián Capelli`} className="hover:text-slate-500 hover:underline">Adrián Capelli</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* BCLA 2019-20 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">BCLA 2019-20</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Fase de grupos</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Emilio Taboada`} className="hover:text-slate-500 hover:underline">Emilio Taboada</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Mauricio Aguiar`} className="hover:text-slate-500 hover:underline">Mauricio Aguiar</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Courtney Fells`} className="hover:text-slate-500 hover:underline">Courtney Fells</a><span>, </span>
                                <a href={`../jugador/Darryl Watkins`} className="hover:text-slate-500 hover:underline">Darryl Watkins</a><span>, </span>
                                <a href={`../jugador/David Doblas`} className="hover:text-slate-500 hover:underline">David Doblas</a><span>, </span>
                                <a href={`../jugador/Jarrett Croff`} className="hover:text-slate-500 hover:underline">Jarrett Croff</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a><span>, </span>
                                <a href={`../jugador/Tony Mitchell`} className="hover:text-slate-500 hover:underline">Tony Mitchell</a><span>, </span>
                                <a href={`../jugador/Weyinmi Rose`} className="hover:text-slate-500 hover:underline">Weyinmi Rose</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Miguel Volcan`} className="hover:text-slate-500 hover:underline">Miguel Volcan</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2021 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2021</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Play-in</p>
                            <p className="text-l font-normal">(1-2 vs CAP)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Diego Soarez`} className="hover:text-slate-500 hover:underline">Diego Soarez</a><span>, </span>
                                <a href={`../jugador/Emilio Taboada`} className="hover:text-slate-500 hover:underline">Emilio Taboada</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Dwayne Davis`} className="hover:text-slate-500 hover:underline">Dwayne Davis</a><span>, </span>
                                <a href={`../jugador/Lee Roberts`} className="hover:text-slate-500 hover:underline">Lee Roberts</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Ramírez`} className="hover:text-slate-500 hover:underline">Agustín Ramírez</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Sarni`} className="hover:text-slate-500 hover:underline">Mateo Sarni</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Adrián Capelli`} className="hover:text-slate-500 hover:underline">Adrián Capelli</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2021-22 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2021-22</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Semifinal</p>
                            <p className="text-l font-normal">(0-3 vs PEÑ)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Federico Pereiras`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Leandro García Morales`} className="hover:text-slate-500 hover:underline">Leandro García Morales</a><span>, </span>
                                <a href={`../jugador/Mathías Calfani`} className="hover:text-slate-500 hover:underline">Mathías Calfani</a><span>, </span>
                                <a href={`../jugador/Sebastián Izaguirre`} className="hover:text-slate-500 hover:underline">Sebastián Izaguirre</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Al Thornton`} className="hover:text-slate-500 hover:underline">Al Thornton</a><span>, </span>
                                <a href={`../jugador/Avry Holmes`} className="hover:text-slate-500 hover:underline">Avry Holmes (cortado)</a><span>, </span>
                                <a href={`../jugador/Chad Posthumus`} className="hover:text-slate-500 hover:underline">Chad Posthumus (cortado)</a><span>, </span>
                                <a href={`../jugador/Jamari Traylor`} className="hover:text-slate-500 hover:underline">Jamari Traylor (cortado)</a><span>, </span>
                                <a href={`../jugador/Jarvis Varnado`} className="hover:text-slate-500 hover:underline">Jarvis Varnado (cortado)</a><span>, </span>
                                <a href={`../jugador/José Ascanio`} className="hover:text-slate-500 hover:underline">José Ascanio</a><span>, </span>
                                <a href={`../jugador/Marcos Mata`} className="hover:text-slate-500 hover:underline">Marcos Mata</a><span>, </span>
                                <a href={`../jugador/Martin Krampelj`} className="hover:text-slate-500 hover:underline">Martin Krampelj</a><span>, </span>
                                <a href={`../jugador/Sebastián Vega`} className="hover:text-slate-500 hover:underline">Sebastián Vega</a><span>, </span>
                                <a href={`../jugador/Tyreek Duren`} className="hover:text-slate-500 hover:underline">Tyreek Duren</a><span>, </span>
                                <a href={`../jugador/Tyrone Lee`} className="hover:text-slate-500 hover:underline">Tyrone Lee (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Joaquín Silveira`} className="hover:text-slate-500 hover:underline">Joaquín Silveira</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Mateo Amarillo`} className="hover:text-slate-500 hover:underline">Mateo Amarillo</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Daniel Seoane`} className="hover:text-slate-500 hover:underline">Daniel Seoane (cortado)</a><span>, </span>
                                <a href={`../jugador/Federico Bavosi`} className="hover:text-slate-500 hover:underline">Federico Bavosi</a><span>, </span>
                                <a href={`../jugador/Nicolás Casalánguida`} className="hover:text-slate-500 hover:underline">Nicolás Casalánguida</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LSB 2022 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LSB 2022</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl px-4">Cuartos de final</div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Facundo Medina</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Theo Metzger`} className="hover:text-slate-500 hover:underline">Theo Metzger</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Eloy Vargas</a><span>, </span>
                                <a href={`../jugador/Jordan Adams`} className="hover:text-slate-500 hover:underline">Jordan Adams</a><span>, </span>
                                <a href={`../jugador/Kyle Fuller`} className="hover:text-slate-500 hover:underline">Kyle Fuller</a><span>, </span>
                                <a href={`../jugador/Sheldon Mac`} className="hover:text-slate-500 hover:underline">Sheldon Mac</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Luciano Planells</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Leandro Ramella`} className="hover:text-slate-500 hover:underline">Leandro Ramella</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2022-23 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[50%] md:-translate-y-0">LUB 2022-23</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <p className="text-xl px-4">Play-in</p>
                            <p className="text-l font-normal">(1-3 vs PEÑ)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                            <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Facundo Medina</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Leandro Cerminato`} className="hover:text-slate-500 hover:underline">Leandro Cerminato</a><span>, </span>
                                <a href={`../jugador/Theo Metzger`} className="hover:text-slate-500 hover:underline">Theo Metzger</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Anthony Young`} className="hover:text-slate-500 hover:underline">Anthony Young (cortado)</a><span>, </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Eloy Vargas</a><span>, </span>
                                <a href={`../jugador/Gelvis Solano`} className="hover:text-slate-500 hover:underline">Gelvis Solano</a><span>, </span>
                                <a href={`../jugador/Jordan Adams`} className="hover:text-slate-500 hover:underline">Jordan Adams (cortado)</a><span>, </span>
                                <a href={`../jugador/Kyle Fuller`} className="hover:text-slate-500 hover:underline">Kyle Fuller (cortado)</a><span>, </span>
                                <a href={`../jugador/Orlando Johnson`} className="hover:text-slate-500 hover:underline">Orlando Johnson (cortado)</a><span>, </span>
                                <a href={`../jugador/Raphiael Putney`} className="hover:text-slate-500 hover:underline">Raphiael Putney</a><span>, </span>
                                <a href={`../jugador/Sheldon Mac`} className="hover:text-slate-500 hover:underline">Sheldon Mac (cortado)</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Felipe García`} className="hover:text-slate-500 hover:underline">Felipe García</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Gabriel Brun</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Luciano Planells</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Leandro Ramella`} className="hover:text-slate-500 hover:underline">Leandro Ramella</a><span>, </span>
                                <a href={`../jugador/Germán Cortizas`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a>
                            </p>
                        </div>
                    </div>
                    <hr className="my-4 border-black" />
                    {/* LUB 2019-20 */}
                    <div className="md:flex items-center px-7 h-fit">
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center text-xl -translate-y-[120%] md:-translate-y-0">LUB 2023-24</div>
                        <div className="inline-block w-1/2 md:w-1/6 font-semibold text-center">
                            <div className="relative text-center cursor-default text-black w-fit m-auto">
                                <img src={star} alt="Estrella" className="w-12" />
                                <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-1/2 text-l font-semibold">11°</div>
                            </div>
                            <p className="text-xl px-4">CAMPEÓN</p>
                            <p className="text-l font-normal">(4-1 vs PEÑ)</p>
                        </div>
                        <div className="block md:inline-block w-full md:w-4/6 mt-2 md:mt-0">
                        <p><span className="font-semibold">Mayores: </span>
                                <a href={`../jugador/Agustín Zuvich`} className="hover:text-slate-500 hover:underline">Agustín Zuvich</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Diego García (Recambio)</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Diego Pena García</a><span>, </span>
                                <a href={`../jugador/Facundo Medina`} className="hover:text-slate-500 hover:underline">Federico Pereiras</a><span>, </span>
                                <a href={`../jugador/Juan Santiso`} className="hover:text-slate-500 hover:underline">Juan Santiso</a><span>, </span>
                                <a href={`../jugador/Leandro Cerminato`} className="hover:text-slate-500 hover:underline">Santiago Vidal</a>
                            </p>
                            <p><span className="font-semibold">Extranjeros: </span>
                                <a href={`../jugador/Eloy Vargas`} className="hover:text-slate-500 hover:underline">Donald Sims</a><span>, </span>
                                <a href={`../jugador/Gelvis Solano`} className="hover:text-slate-500 hover:underline">Luís Santos</a><span>, </span>
                                <a href={`../jugador/Raphiael Putney`} className="hover:text-slate-500 hover:underline">Victor Rudd</a>
                            </p>
                            <p><span className="font-semibold">Sub 23: </span>
                                <a href={`../jugador/Agustín Gentile`} className="hover:text-slate-500 hover:underline">Agustín Gentile</a><span>, </span>
                                <a href={`../jugador/Felipe García`} className="hover:text-slate-500 hover:underline">Bruno Curadossi</a><span>, </span>
                                <a href={`../jugador/Gabriel Brun`} className="hover:text-slate-500 hover:underline">Facundo Villalba</a><span>, </span>
                                <a href={`../jugador/Juan Stoll`} className="hover:text-slate-500 hover:underline">Juan Stoll</a><span>, </span>
                                <a href={`../jugador/Lucas Silva`} className="hover:text-slate-500 hover:underline">Lucas Silva</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Manuel Fernández</a><span>, </span>
                                <a href={`../jugador/Luciano Planells`} className="hover:text-slate-500 hover:underline">Valentino Acuña</a>
                            </p>
                            <p><span className="font-semibold">DT: </span>
                                <a href={`../jugador/Adrián Capelli`} className="hover:text-slate-500 hover:underline">Germán Cortizas</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>

        </div>
    );
}

export default Planteles;
