import React, { useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import Collapsible from 'react-collapsible';
import Footer from "./footer";

function Records() {

    const [isFetched, setIsFetched] = useState(false);

    // Totales
    const [partidosJugador, setPartidosJugador] = useState([]);
    const [ganadosJugador, setGanadosJugador] = useState([]);
    const [minJugador, setMinutosJugador] = useState([]);
    const [ptsJugador, setPuntosJugador] = useState([]);
    const [rtJugador, setRtJugador] = useState([]);
    const [astJugador, setAstJugador] = useState([]);
    const [stlJugador, setStlJugador] = useState([]);
    const [blsJugador, setBlsJugador] = useState([]);
    // Promedios
    const [minPromJugador, setMinPromJugador] = useState([]);
    const [ptsPromJugador, setPuntosPromJugador] = useState([]);
    const [rtPromJugador, setRtPromJugador] = useState([]);
    const [astPromJugador, setAstPromJugador] = useState([]);
    const [stlPromJugador, setStlPromJugador] = useState([]);
    const [blsPromJugador, setBlsPromJugador] = useState([]);
    const [campoPercPromJugador, setCampoPercPromJugador] = useState([]);
    const [librePercPromJugador, setLibrePercPromJugador] = useState([]);
    const [doblePercPromJugador, setDoblePercPromJugador] = useState([]);
    const [triplePercPromJugador, setTriplePercPromJugador] = useState([]);
    // Máximos por partido
    const [puntosMaximo, setpuntosMaximo] = useState([]);
    const [rebotesTotMaximo, setRebotesTotMaximo] = useState([]);
    const [asistenciasMaximo, setAsistenciasMaximo] = useState([]);
    const [robosMaximo, setRobosMaximo] = useState([]);
    const [tapasMaximo, setTapasMaximo] = useState([]);
    const [libresHechosMaximo, setLibresHechosMaximo] = useState([]);
    const [doblesHechosMaximo, setDoblesHechosMaximo] = useState([]);
    const [triplesHechosMaximo, setTriplesHechosMaximo] = useState([]);
    // Promedios por temporada
    const [promTemporadas, setPromTemporadas] = useState([]);
    // DT
    const [partidosDT, setPartidosDT] = useState([]);
    const [ganadosDT, setGanadosDT] = useState([]);
    const [winPercDT, setWinPercDT] = useState([]);

    useEffect(() => {
        Promise.all([
            // Totales
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/partidosJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/ganadosJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/minJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/ptsJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/rtJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/astJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/stlJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/blsJugador'),
            // Promedios
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/minPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/ptsPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/rtPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/astPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/stlPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsPromedios/blsPromJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/tcpercJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/tlpercJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/tdpercJugador'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/ttpercJugador'),
            // Máximos por partido
            fetch('https://aguada-stats-api.vercel.app/maximos/puntos'),
            fetch('https://aguada-stats-api.vercel.app/maximos/rebotesTot'),
            fetch('https://aguada-stats-api.vercel.app/maximos/asistencias'),
            fetch('https://aguada-stats-api.vercel.app/maximos/robos'),
            fetch('https://aguada-stats-api.vercel.app/maximos/tapas'),
            fetch('https://aguada-stats-api.vercel.app/maximos/libresHechos'),
            fetch('https://aguada-stats-api.vercel.app/maximos/doblesHechos'),
            fetch('https://aguada-stats-api.vercel.app/maximos/triplesHechos'),
            // Promedio por temporada
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTemporada'),
            // DT
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/partidosDT'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/ganadosDT'),
            fetch('https://aguada-stats-jugadores-api.vercel.app/recordsTotales/winPercDT'),
        ])
            .then(([resPartidosJugador, resGanadosJugador, resMinJugador, resPtsJugador, resRtJugador, resAstJugador, resStlJugador, resBlsJugador,
                resMinPromJugador, resPtsPromJugador, resRtPromJugador, resAstPromJugador, resStlPromJugador, resBlsPromJugador, resCampoPercJugador, resLibresPercJugador, resDoblesPercJugador, resTriplesPercJugador,
                resPuntosMaximo, resRebotesTotMaximo, resAsistenciasMaximo, resRobosMaximo, resTapasMaximo, resLibresHechosMaximo, resDoblesHechosMaximo, resTriplesHechosMaximo,
                resPromTemporada, partidosDT, ganadosDT, winPercDT]) =>
                Promise.all([resPartidosJugador.json(), resGanadosJugador.json(), resMinJugador.json(), resPtsJugador.json(), resRtJugador.json(), resAstJugador.json(), resStlJugador.json(), resBlsJugador.json(),
                resMinPromJugador.json(), resPtsPromJugador.json(), resRtPromJugador.json(), resAstPromJugador.json(), resStlPromJugador.json(), resBlsPromJugador.json(), resCampoPercJugador.json(), resLibresPercJugador.json(), resDoblesPercJugador.json(), resTriplesPercJugador.json(),
                resPuntosMaximo.json(), resRebotesTotMaximo.json(), resAsistenciasMaximo.json(), resRobosMaximo.json(), resTapasMaximo.json(), resLibresHechosMaximo.json(), resDoblesHechosMaximo.json(), resTriplesHechosMaximo.json(),
                resPromTemporada.json(), partidosDT.json(), ganadosDT.json(), winPercDT.json()])
            )
            .then(([dataPartidosJugador, dataGanadosJugador, dataMinJugador, dataPtsJugador, dataRtJugador, dataAstJugador, dataStlJugador, dataBlsJugador,
                dataMinPromJugador, dataPtsPromJugador, dataRtPromJugador, dataAstPromJugador, dataStlPromJugador, dataBlsPromJugador, dataCampoPercJugador, dataLibresPercJugador, dataDoblesPercJugador, dataTriplesPercJugador,
                dataPuntosMaximo, dataRebotesTot, dataAsistenciasMaximo, dataRobosMaximo, dataTapasMaximo, dataLibresHechosMaximo, dataDoblesHechosMaximo, dataTriplesHechosMaximo,
                dataPromTemporada, dataPartidosDT, dataGanadosDT, dataWinPercDT]) => {
                // Totales
                setPartidosJugador(dataPartidosJugador);
                setGanadosJugador(dataGanadosJugador);
                setMinutosJugador(dataMinJugador);
                setPuntosJugador(dataPtsJugador);
                setRtJugador(dataRtJugador);
                setAstJugador(dataAstJugador);
                setStlJugador(dataStlJugador);
                setBlsJugador(dataBlsJugador);
                // Promedios
                setMinPromJugador(dataMinPromJugador);
                setPuntosPromJugador(dataPtsPromJugador);
                setRtPromJugador(dataRtPromJugador);
                setAstPromJugador(dataAstPromJugador);
                setStlPromJugador(dataStlPromJugador);
                setBlsPromJugador(dataBlsPromJugador);
                setCampoPercPromJugador(dataCampoPercJugador);
                setLibrePercPromJugador(dataLibresPercJugador);
                setDoblePercPromJugador(dataDoblesPercJugador);
                setTriplePercPromJugador(dataTriplesPercJugador);
                // Maximos por partido
                setpuntosMaximo(dataPuntosMaximo);
                setRebotesTotMaximo(dataRebotesTot);
                setAsistenciasMaximo(dataAsistenciasMaximo);
                setRobosMaximo(dataRobosMaximo);
                setTapasMaximo(dataTapasMaximo);
                setLibresHechosMaximo(dataLibresHechosMaximo);
                setDoblesHechosMaximo(dataDoblesHechosMaximo);
                setTriplesHechosMaximo(dataTriplesHechosMaximo);
                // Promedios por temporada
                setPromTemporadas(dataPromTemporada);
                // DT
                setPartidosDT(dataPartidosDT);
                setGanadosDT(dataGanadosDT);
                setWinPercDT(dataWinPercDT);

                setIsFetched(true);
            });
        document.title = 'Récords';
    }, []);

    let allTorneos = [], mpgTorneos = [], ppgTorneos = [], rtpgTorneos = [], apgTorneos = [], spgTorneos = [], bpgTorneos = [], tcpercTorneos = [], tlpercTorneos = [], tdpercTorneos = [], ttpercTorneos = [];
    const manageMaxPromTemp = () => {
        for (let i = 0; i < promTemporadas.length; i++) {
            for (let j = 0; j < promTemporadas[i].torneosProm.length; j++) {
                if (promTemporadas[i].torneosProm[j].liga.includes("LUB") && promTemporadas[i].torneos[j].partidos > 24) {
                    let torneo = {
                        nombre: promTemporadas[i].nombre,
                        nombreAbrev: promTemporadas[i].nombreAbrev,
                        torneo: promTemporadas[i].torneosProm[j]
                    }
                    mpgTorneos.push(torneo);
                    ppgTorneos.push(torneo);
                    rtpgTorneos.push(torneo);
                    apgTorneos.push(torneo);
                    spgTorneos.push(torneo);
                    bpgTorneos.push(torneo);
                }
                if (promTemporadas[i].torneosProm[j].liga.includes("LUB") && promTemporadas[i].torneos[j].tcl > 200) {
                    let torneoPerc = {
                        nombre: promTemporadas[i].nombre,
                        nombreAbrev: promTemporadas[i].nombreAbrev,
                        torneo: promTemporadas[i].torneosProm[j]
                    }
                    tcpercTorneos.push(torneoPerc);
                }
                if (promTemporadas[i].torneosProm[j].liga.includes("LUB") && promTemporadas[i].torneos[j].tll > 150) {
                    let torneoPerc = {
                        nombre: promTemporadas[i].nombre,
                        nombreAbrev: promTemporadas[i].nombreAbrev,
                        torneo: promTemporadas[i].torneosProm[j]
                    }
                    tlpercTorneos.push(torneoPerc);
                }
                if (promTemporadas[i].torneosProm[j].liga.includes("LUB") && promTemporadas[i].torneos[j].tdl > 100) {
                    let torneoPerc = {
                        nombre: promTemporadas[i].nombre,
                        nombreAbrev: promTemporadas[i].nombreAbrev,
                        torneo: promTemporadas[i].torneosProm[j]
                    }
                    tdpercTorneos.push(torneoPerc);
                }
                if (promTemporadas[i].torneosProm[j].liga.includes("LUB") && promTemporadas[i].torneos[j].ttl > 100) {
                    let torneoPerc = {
                        nombre: promTemporadas[i].nombre,
                        nombreAbrev: promTemporadas[i].nombreAbrev,
                        torneo: promTemporadas[i].torneosProm[j]
                    }
                    ttpercTorneos.push(torneoPerc);
                }
            }
        }
        mpgTorneos.sort((a, b) => b.torneo.mpg - a.torneo.mpg).splice(5);
        ppgTorneos.sort((a, b) => b.torneo.ppg - a.torneo.ppg).splice(5);
        rtpgTorneos.sort((a, b) => b.torneo.rtpg - a.torneo.rtpg).splice(5);
        apgTorneos.sort((a, b) => b.torneo.apg - a.torneo.apg).splice(5);
        spgTorneos.sort((a, b) => b.torneo.spg - a.torneo.spg).splice(5);
        bpgTorneos.sort((a, b) => b.torneo.bpg - a.torneo.bpg).splice(5);
        tcpercTorneos.sort((a, b) => b.torneo.tcperc.localeCompare(a.torneo.tcperc, undefined, { numeric: true })).splice(5);
        tlpercTorneos.sort((a, b) => b.torneo.tlperc.localeCompare(a.torneo.tlperc, undefined, { numeric: true })).splice(5);
        tdpercTorneos.sort((a, b) => b.torneo.tdperc.localeCompare(a.torneo.tdperc, undefined, { numeric: true })).splice(5);
        ttpercTorneos.sort((a, b) => b.torneo.ttperc.localeCompare(a.torneo.ttperc, undefined, { numeric: true })).splice(5);
    }

    return (
        <div className="bg-fondo bg-center bg-no-repeat bg-cover bg-fixed min-h-screen px-4 md:px-20 pt-6">
            <h1 className="text-center mt-14 mb-8 text-3xl text-white font-semibold">Récords</h1>
            <div className="backdrop-blur-md bg-gradient-to-r from-white/70 to-white/60 inherit mb-5 py-5 px-2 cellphone:p-5 rounded-lg">

                {isFetched ?
                    <>
                        <div className="block md:hidden">
                            <Collapsible trigger="Totales en carrera" className="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                openedClassName="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                open={false}>
                                <div className="flex flex-wrap text-center mt-4 text-base">
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Partidos" className="text-center text-base font-semibold block w-full"
                                            openedClassName="text-center text-base font-semibold block w-full"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {partidosJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (partidosJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.partidos}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Ganados" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {ganadosJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (ganadosJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.ganados}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Minutos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {minJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (minJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.min}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Puntos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {ptsJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (ptsJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.pts}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Rebotes" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {rtJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (rtJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.rt}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Asistencias" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {astJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (astJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.ast}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Robos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {stlJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (stlJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.stl}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Tapas" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {blsJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (blsJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carrera.bls}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                </div>
                            </Collapsible>
                            <Collapsible trigger="Promedios en carrera" className="text-center text-lg cellphone:text-xl font-semibold mt-5"
                                openedClassName="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                open={false}>
                                <p className="text-center text-sm cellphone:text-base text-gray-700 mb-4">(Mínimo 30 partidos jugados)</p>
                                <div className="flex flex-wrap text-center mt-4 text-base">
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Minutos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {minPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (minPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.mpg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Puntos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {ptsPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (ptsPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.ppg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Rebotes" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {rtPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (rtPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.rtpg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Asistencias" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {astPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (astPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.apg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Robos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {stlPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (stlPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.spg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Tapas" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {blsPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (blsPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.bpg}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Campo %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {campoPercPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (campoPercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.tcperc}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Libres %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {librePercPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (librePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.tlperc}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Dobles %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {doblePercPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (doblePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.tdperc}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Triples %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                <tbody>
                                                    {triplePercPromJugador.map((jugador, index) => <>
                                                        <tr className={`${index < (triplePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.carreraProm.ttperc}</td>
                                                        </tr>
                                                    </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Collapsible>
                                    </div>
                                </div>
                            </Collapsible>

                            <Collapsible trigger="Máximo en partido" className="text-center text-lg cellphone:text-xl font-semibold mt-5"
                                openedClassName="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                open={false}>
                                <div className="flex flex-wrap text-center mt-4 text-base">
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Puntos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {puntosMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.puntos}</td>
                                                        </tr>
                                                        <tr className={`${index < (puntosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200 mt-20">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Rebotes" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {rebotesTotMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.rebotesTot}</td>
                                                        </tr>
                                                        <tr className={`${index < (rebotesTotMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Asistencias" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {asistenciasMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.asistencias}</td>
                                                        </tr>
                                                        <tr className={`${index < (asistenciasMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Robos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {robosMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.robos}</td>
                                                        </tr>
                                                        <tr className={`${index < (robosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Tapas" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {tapasMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.tapas}</td>
                                                        </tr>
                                                        <tr className={`${index < (tapasMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Libres Hechos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {libresHechosMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.libresHechos}</td>
                                                        </tr>
                                                        <tr className={`${index < (libresHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Dobles Hechos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {doblesHechosMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.doblesHechos}</td>
                                                        </tr>
                                                        <tr className={`${index < (doblesHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Triples Hechos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {triplesHechosMaximo.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                            <td className="w-1/6">{jugador.jugador.triplesHechos}</td>
                                                        </tr>
                                                        <tr className={`${index < (triplesHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                </div>
                            </Collapsible>
                            <Collapsible trigger="Promedios en temporada" className="text-center text-lg cellphone:text-xl font-semibold mt-5"
                                openedClassName="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                open={false}>
                                {manageMaxPromTemp()}
                                <div className="flex flex-wrap text-center mt-4 text-base">
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Minutos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {mpgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.mpg}</td>
                                                        </tr>
                                                        <tr className={`${index < (mpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Puntos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {ppgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.ppg}</td>
                                                        </tr>
                                                        <tr className={`${index < (ppgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Rebotes" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {rtpgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.rtpg}</td>
                                                        </tr>
                                                        <tr className={`${index < (rtpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Asistencias" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {apgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.apg}</td>
                                                        </tr>
                                                        <tr className={`${index < (apgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Robos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {spgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.spg}</td>
                                                        </tr>
                                                        <tr className={`${index < (spgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Tapas" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {bpgTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.bpg}</td>
                                                        </tr>
                                                        <tr className={`${index < (bpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Campo %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {tcpercTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.tcperc}</td>
                                                        </tr>
                                                        <tr className={`${index < (tcpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Libres %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {tlpercTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.tlperc}</td>
                                                        </tr>
                                                        <tr className={`${index < (tlpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Dobles %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {tdpercTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.tdperc}</td>
                                                        </tr>
                                                        <tr className={`${index < (tdpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Triples %" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {ttpercTorneos.map((jugador, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{jugador.torneo.ttperc}</td>
                                                        </tr>
                                                        <tr className={`${index < (ttpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                            <td colspan="3">
                                                                <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                </div>
                            </Collapsible>
                            <Collapsible trigger="Totales DT" className="text-center text-lg cellphone:text-xl font-semibold mt-5"
                                openedClassName="text-center text-lg cellphone:text-xl font-semibold mt-3"
                                open={false}>
                                <p className="text-center text-sm cellphone:text-base text-gray-700 mb-4">(Mínimo 30 partidos dirigidos)</p>
                                <div className="flex flex-wrap text-center mt-4 text-base">
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                        <Collapsible trigger="Partidos" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {partidosDT.map((dt, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{dt.carrera.partidos}</td>
                                                        </tr>
                                                        <tr className={`${index < (partidosDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="Ganados" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {ganadosDT.map((dt, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{dt.carrera.ganados}</td>
                                                        </tr>
                                                        <tr className={`${index < (ganadosDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                    <div className="w-full sm:w-1/2 sm:m-auto md:w-1/4 mb-6 px-2">
                                        <Collapsible trigger="% Ganados" className="text-center text-base font-semibold"
                                            openedClassName="text-center text-base font-semibold"
                                            open={false}>
                                            <table className="w-full mt-2 font-normal">
                                                {winPercDT.map((dt, index) => <>
                                                    <tbody>
                                                        <tr>
                                                            <td className="w-1/6">{index + 1}</td>
                                                            <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                            <td className="w-1/6">{dt.carrera.winPerc}</td>
                                                        </tr>
                                                        <tr className={`${index < (winPercDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                )}
                                            </table>
                                        </Collapsible>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>

                        <div className="hidden md:block">
                            <h1 className="text-center text-xl mb-4 font-semibold underline">Totales en carrera</h1>
                            <div className="flex flex-wrap text-center">
                                <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Partidos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {partidosJugador.map((jugador, index) => <>
                                                <tr className={`${index < (partidosJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.partidos}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Ganados</p>
                                    <table className="w-full">
                                        <tbody>
                                            {ganadosJugador.map((jugador, index) => <>
                                                <tr className={`${index < (ganadosJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.ganados}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/4 mb-6 px-2 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Minutos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {minJugador.map((jugador, index) => <>
                                                <tr className={`${index < (minJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.min}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Puntos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {ptsJugador.map((jugador, index) => <>
                                                <tr className={`${index < (ptsJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.pts}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Rebotes</p>
                                    <table className="w-full">
                                        <tbody>
                                            {rtJugador.map((jugador, index) => <>
                                                <tr className={`${index < (rtJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.rt}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Asistencias</p>
                                    <table className="w-full">
                                        <tbody>
                                            {astJugador.map((jugador, index) => <>
                                                <tr className={`${index < (astJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.ast}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Robos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {stlJugador.map((jugador, index) => <>
                                                <tr className={`${index < (stlJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.stl}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Tapas</p>
                                    <table className="w-full">
                                        <tbody>
                                            {blsJugador.map((jugador, index) => <>
                                                <tr className={`${index < (blsJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carrera.bls}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h1 className="text-center text-xl mt-4 underline font-semibold">Promedios en carrera</h1>
                            <p className="text-center text-md text-gray-700 mb-4">(Mínimo 30 partidos jugados)</p>
                            <div className="flex flex-wrap text-center">
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Minutos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {minPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (minPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.mpg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Puntos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {ptsPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (ptsPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.ppg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Rebotes</p>
                                    <table className="w-full">
                                        <tbody>
                                            {rtPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (rtPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.rtpg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Asistencias</p>
                                    <table className="w-full">
                                        <tbody>
                                            {astPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (astPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.apg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Robos</p>
                                    <table className="w-full">
                                        <tbody>
                                            {stlPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (stlPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.spg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Tapas</p>
                                    <table className="w-full">
                                        <tbody>
                                            {blsPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (blsPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.bpg}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 md:border-r-0 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Campo %</p>
                                    <table className="w-full">
                                        <tbody>
                                            {campoPercPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (campoPercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.tcperc}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Libres %</p>
                                    <table className="w-full">
                                        <tbody>
                                            {librePercPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (librePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.tlperc}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Dobles %</p>
                                    <table className="w-full">
                                        <tbody>
                                            {doblePercPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (doblePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.tdperc}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6">
                                    <p className="text-md font-semibold mb-2">Triples %</p>
                                    <table className="w-full">
                                        <tbody>
                                            {triplePercPromJugador.map((jugador, index) => <>
                                                <tr className={`${index < (triplePercPromJugador.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.carreraProm.ttperc}</td>
                                                </tr>
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h1 className="text-center text-xl my-4 underline font-semibold">Máximo en partido</h1>
                            <div className="flex flex-wrap text-center">
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Puntos</p>
                                    <table className="w-full">
                                        {puntosMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.puntos}</td>
                                                </tr>
                                                <tr className={`${index < (puntosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200 mt-20">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Rebotes</p>
                                    <table className="w-full">
                                        {rebotesTotMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.rebotesTot}</td>
                                                </tr>
                                                <tr className={`${index < (rebotesTotMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Asistencias</p>
                                    <table className="w-full">
                                        {asistenciasMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.asistencias}</td>
                                                </tr>
                                                <tr className={`${index < (asistenciasMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Robos</p>
                                    <table className="w-full">
                                        {robosMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.robos}</td>
                                                </tr>
                                                <tr className={`${index < (robosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Tapas</p>
                                    <table className="w-full">
                                        {tapasMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.tapas}</td>
                                                </tr>
                                                <tr className={`${index < (tapasMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Libres Hechos</p>
                                    <table className="w-full">
                                        {libresHechosMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.libresHechos}</td>
                                                </tr>
                                                <tr className={`${index < (libresHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Dobles Hechos</p>
                                    <table className="w-full">
                                        {doblesHechosMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.doblesHechos}</td>
                                                </tr>
                                                <tr className={`${index < (doblesHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Triples Hechos</p>
                                    <table className="w-full">
                                        {triplesHechosMaximo.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.jugador.jugador}`} className="hover:text-slate-200 hover:underline">{jugador.jugador.jugador[0]}.{jugador.jugador.jugador.substring(jugador.jugador.jugador.indexOf(' '))}</a></td>
                                                    <td className="w-1/6">{jugador.jugador.triplesHechos}</td>
                                                </tr>
                                                <tr className={`${index < (triplesHechosMaximo.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <a href={`partido/${jugador._id}`} className="text-sm text-gray-700 hover:text-slate-200">(vs {jugador.adversario} {jugador.liga})</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                            </div>
                            <h1 className="text-center text-xl my-4 underline font-semibold">Promedios en temporada</h1>
                            {/* {manageMaxPromTemp()} */}
                            <div className="flex flex-wrap text-center">
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Minutos</p>
                                    <table className="w-full">
                                        {mpgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.mpg}</td>
                                                </tr>
                                                <tr className={`${index < (mpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Puntos</p>
                                    <table className="w-full">
                                        {ppgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.ppg}</td>
                                                </tr>
                                                <tr className={`${index < (ppgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Rebotes</p>
                                    <table className="w-full">
                                        {rtpgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.rtpg}</td>
                                                </tr>
                                                <tr className={`${index < (rtpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/4 mb-6">
                                    <p className="text-md font-semibold mb-2">Asistencias</p>
                                    <table className="w-full">
                                        {apgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.apg}</td>
                                                </tr>
                                                <tr className={`${index < (apgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Robos</p>
                                    <table className="w-full">
                                        {spgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.spg}</td>
                                                </tr>
                                                <tr className={`${index < (spgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Tapas</p>
                                    <table className="w-full">
                                        {bpgTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.bpg}</td>
                                                </tr>
                                                <tr className={`${index < (bpgTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 border-r-0 sm:border-r-2 border-green-600 md:border-r-0">
                                    <p className="text-md font-semibold mb-2">Campo %</p>
                                    <table className="w-full">
                                        {tcpercTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.tcperc}</td>
                                                </tr>
                                                <tr className={`${index < (tcpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Libres %</p>
                                    <table className="w-full">
                                        {tlpercTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.tlperc}</td>
                                                </tr>
                                                <tr className={`${index < (tlpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Dobles %</p>
                                    <table className="w-full">
                                        {tdpercTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.tdperc}</td>
                                                </tr>
                                                <tr className={`${index < (tdpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6">
                                    <p className="text-md font-semibold mb-2">Triples %</p>
                                    <table className="w-full">
                                        {ttpercTorneos.map((jugador, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${jugador.nombre}`} className="hover:text-slate-200 hover:underline">{jugador.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{jugador.torneo.ttperc}</td>
                                                </tr>
                                                <tr className={`${index < (ttpercTorneos.length - 1) && `border-b-2 border-slate-500`} `}>
                                                    <td colspan="3">
                                                        <p className="text-sm text-gray-700">({jugador.torneo.liga})</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                            </div>
                            <h1 className="text-center text-xl mt-4 underline font-semibold">Totales DT</h1>
                            <p className="text-center text-md text-gray-700 mb-4">(Mínimo 30 partidos dirigidos)</p>
                            <div className="flex flex-wrap text-center">
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 sm:border-r-2 sm:border-green-600">
                                    <p className="text-md font-semibold mb-2">Partidos</p>
                                    <table className="w-full">
                                        {partidosDT.map((dt, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{dt.carrera.partidos}</td>
                                                </tr>
                                                <tr className={`${index < (partidosDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6 md:border-r-2 md:border-green-600">
                                    <p className="text-md font-semibold mb-2">Ganados</p>
                                    <table className="w-full">
                                        {ganadosDT.map((dt, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{dt.carrera.ganados}</td>
                                                </tr>
                                                <tr className={`${index < (ganadosDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                                <div className="w-full px-2 sm:w-1/2 md:w-1/3 mb-6">
                                    <p className="text-md font-semibold mb-2">% Ganados</p>
                                    <table className="w-full">
                                        {winPercDT.map((dt, index) => <>
                                            <tbody>
                                                <tr>
                                                    <td className="w-1/6">{index + 1}</td>
                                                    <td><a href={`jugador/${dt.nombre}`} className="hover:text-slate-200 hover:underline">{dt.nombreAbrev}</a></td>
                                                    <td className="w-1/6">{dt.carrera.winPerc}</td>
                                                </tr>
                                                <tr className={`${index < (winPercDT.length - 1) && `border-b-2 border-slate-500`} `}>
                                                </tr>
                                            </tbody>
                                        </>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>
                    : <div className="backdrop-blur-xl bg-white/20 p-10 rounded-md">
                        <ReactLoading type="bars" color="#15803d" width={'10%'} className="text-center m-auto mb-16" />
                        <p className="text-center text-lg font-semibold mt-4">Buscando récords...</p>
                    </div>
                }

            </div>
            <Footer></Footer>

        </div>
    );
}

export default Records;
